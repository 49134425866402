@import 'sportsbook/mobile-web/shared/components/odds';
@import 'sportsbook/mobile-web/shared/components/odds-table';

.odds {
  --odds-line-bg: #1f1f23;
  --odds-increase: #00b372;
  --odds-decrease: #f10;
  --odds-indicator-color: #9b9b9b;
  --odds-name-color: var(--neutral-light-3);
}

.single-game .odds {
  --odds-value-color: var(--white);
}

.single-game {
  --score-col-gap: 10px;
}

.odds.is-active {
  --odds-bg: var(--basic-color);
  --odds-color: var(--black);
  --odds-name-color: var(--black);
  --odds-value-color: var(--black);
  --odds-indicator-color: var(--black);
}

.odds-table {
  --score-border: var(--border-3);
  --odds-table-gy: 25px;
  --games-gy: 15px;
}

.headline-sport {
  --sport-color: var(--neutral-light-2);
  --league-color: var(--white);
}

.section-headline__filter {
  --filter-bg: var(--neutral-dark-1);
  --filter-border: var(--border-3);
  --filter-radius: var(--radius-2);
  --filter-border-color-active: var(--basic-color-dark);

  &.is-active {
    --filter-bg: var(--basic-color-dark);
  }
}

.odds-table__markets {
  z-index: 2;
  overflow: hidden;
  position: sticky;
  top: calc(110px + var(--top-space-height) + var(--safe-area-top));
  grid-template-columns: auto;
  width: 100%;
  background-color: var(--body);
  transition: top 0.3s ease;
  box-shadow: 0px 0px 5px 10px var(--body);
}

.has-scroll-top .odds-table__markets {
  top: calc(165px + var(--top-space-height) + var(--safe-area-top));
}

.has-scroll-top .subpage-games--live .odds-table__markets {
  top: calc(150px + var(--top-space-height) + var(--safe-area-top));
}

.live-game .single-game,
.single-game.has-outrights {
  grid-template-columns: 1fr;
  grid-gap: var(--space-4);
}

.live-game {
  .single-game-bar {
    margin-bottom: var(--space-4);
  }

  .odds__name {
    max-width: 100%;
    margin-bottom: 2px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    @include ellipsis;
  }
}

.odds-table .single-game-score {
  max-width: 180px;

  &__content {
    padding-right: 0;
  }

  &__content::before {
    bottom: 26px;
  }
}

.game-entries--custom {
  .headline-sport {
    display: none;
  }

  .game-entries__group {
    margin: 0;
  }
}

.subpage-games--promoted {
  .single-game-score {
    padding-right: var(--space-2);
  }
}
