@import 'sportsbook/mobile-web/shared/layout/breadcrumbs';

.breadcrumbs {
  --breadcrumb-bg: var(--bg-1);
  --breadcrumb-color: var(--color-2);
  --breadcrumb-border: var(--border-1);
  --breadcrumb-gap: 15px;
  --breadcrumb-radius: var(--radius-1);
}

.breadcrumbs {
  padding: 5px 10px;
}

.breadcrumbs-entry {
  &__btn {
    background-position: center;
    background-size: 18px;
    background-repeat: no-repeat;
  }
}

.breadcrumbs-entry .icon-cp {
  display: flex;
  width: 18px;
  height: 18px;

  &.icon-arrow-back {
    fill: #fff;
  }
}

.breadcrumbs-entry--item div {
  border: none;
}
