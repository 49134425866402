@import 'sportsbook/mobile-web/shared/layout/footer';

.icon {
  &-latvijas-republikas {
    width: 30px;
    height: 26px;
  }

  &-secured18 {
    width: 28px;
    height: 28px;
  }

  &-twitter {
    width: 17px;
    height: 17px;
  }

  &-instagram {
    width: 24px;
    height: 24px;
  }

  &-telegram {
    width: 20px;
    height: 20px;
  }

  &-vefriga {
    width: 55px;
    height: 18px;
  }

  &-eparaksts {
    width: 131px;
    height: 20px;
  }

  &-smartid {
    width: 130px;
    height: 20px;
  }

  &-blik {
    width: 40px;
    height: 21px;
  }

  &-przelewy24 {
    width: 70px;
    height: 24px;
  }

  &-paysafecard {
    width: 140px;
    height: 24px;
  }

  &-google-pay {
    width: 58px;
    height: 23px;
  }

  &-apple-pay {
    width: 38px;
    height: 24px;
  }

  &-plix {
    width: 53px;
    height: 22px;
  }

  &-paypal {
    width: 90px;
    height: 22px;
  }

  &-dotpay-cards {
    width: 110px;
    height: 22px;
  }

  &-slask-wroclaw {
    width: 40px;
    height: 42px;
  }

  &-coco-jambo {
    width: 34px;
    height: 40px;
  }

  &-piast-gliwice {
    width: 40px;
    height: 40px;
  }

  &-kspro-fighters-league {
    width: 46px;
    height: 50px;
  }

  &-lucky-punch {
    width: 89px;
    height: 40px;
  }
  &-latvia-drift {
    width: 84px;
  }

  &-leevon-ppk {
    width: 52px;
    height: 68px;
  }

  &-riga-fc {
    height: 68px;
    width: 68px;
  }

  &-fk-auda {
    height: 68px;
    width: 59px;
  }

  &-371 {
    height: 40px;
    width: 72px;
  }
}

.footer {
  &__brand {
    margin: 0 0 25px 0;
  }

  &__brands {
    &-list-box {
      justify-content: center;
      gap: 15px;
    }

    &-title {
      text-align: center;
    }
  }
}
