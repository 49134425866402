.right-menu {

  &__cash {

    &-title {

      span {
        --bg-green: var(--primary-green);
      }
    }

  }

  &__links {
    //li {
    //  &:last-of-type {
    //    margin-bottom: 0;
    //  }
    //}

    &--secondary {
      li {
        a {
          color: #56565A;
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
  }


  &__link {

    &--secondary {
      --link-color-2: #56565A;
    }
  }
}
