.icon-cp {
  display: inline-flex;
}

.icon-cp svg {
  display: inline-block;
  width: 100%;
  height: 100%;
  transform: translate(0, 0);
}

.icon-state-toggle.is-active {
  //SPORTS
  .cls-1-sports {
    fill: #fff;
  }

  .cls-2-sports {
    fill: #50c47b;
  }

  //LIVE
  .cls-1-live {
    fill: #f10;
  }

  .cls-2-live {
    fill: #fff;
  }

  //ESPORT
  .cls-1-esport {
    fill: #fff;
  }

  .cls-2-esport {
    fill: #01ff85;
  }

  //GAME CARDS
  .cls-1-gamecards {
    fill: #fff;
  }

  .cls-2-gamecards {
    fill: #f10;
  }

  //VIRTUAL SPORTS
  .cls-1-virtualsports {
    fill: #fff;
  }

  .cls-2-virtualsports {
    fill: #96ebff;
  }

  //MY BETS
  .cls-1-mybets {
    fill: #f4c601;
  }

  //User
  .cls-1-user {
    fill: #fff;
  }

  .cls-2-user {
    fill: #fbcb00;
  }

  //Settings
  .cls-1-settings {
    fill: #fff;
  }

  .cls-2-settings {
    fill: #456e75;
  }

  //Bonus
  .cls-1-bonus {
    fill: #fed796;
  }

  .cls-2-bonus {
    fill: #f4c601;
  }

  //My documents
  .cls-1-mydocuments {
    fill: #456e75;
  }

  .cls-2-mydocuments {
    fill: #fff;
  }

  //Mobile app
  .cls-1-mobileapp {
    fill: #ccc;
  }

  .cls-2-mobileapp {
    fill: #f5bc8f;
  }

  //Cashout
  .cls-1-cashout {
    fill: #50c47b;
  }

  .cls-2-cashout {
    fill: #96ebff;
  }

  //Vip
  .cls-1-vip {
    fill: #f4c601;
  }

  .cls-2-vip {
    fill: #b9b9bd;
  }

  //chat
  .cls-1-chat {
    fill: #456e75;
  }

  .cls-2-chat {
    fill: #96ebff;
  }

  //pin
  .cls-1-pin {
    fill: var(--basic-color);
  }

  .cls-2-pin {
    fill: var(--basic-color);
  }

  //promotions
  .icon-promotions path {
    fill: #f4c601;
  }

  .icon-bet-builder path {
    fill: var(--basic-color);
  }

  .cls-1-boa {
    fill: var(--basic-color);
  }

  .cls-2-boa {
    fill: #fff3be;
  }
}

.side-menu,
.right-menu {
  .icon-state-toggle.is-active {
    //SPORTS
    .cls-1-sports {
      fill: #C7A100;
    }

    .cls-2-sports {
      fill: #FBCD00;
    }

    //LIVE
    .cls-1-live {
      fill: #C7A100;
    }

    .cls-2-live {
      fill: #FBCD00;
    }

    //ESPORT
    .cls-1-esport {
      fill: #C7A100;
    }

    .cls-2-esport {
      fill: #FBCD00;
    }

    //GAME CARDS
    .cls-1-gamecards {
      fill: #C7A100;
    }

    .cls-2-gamecards {
      fill: #FBCD00;
    }

    //VIRTUAL SPORTS
    .cls-1-virtualsports {
      fill: #C7A100;
    }

    .cls-2-virtualsports {
      fill: #FBCD00;
    }

    //MY BETS
    .cls-1-mybets {
      fill: #C7A100;
    }

    //User
    .cls-1-user {
      fill: #C7A100;
    }

    .cls-2-user {
      fill: #FBCD00;
    }

    .icon-promo-codes,
    .icon-esport,
    .icon-virtual-sports {
      path {
        fill: #C7A100;
      }
      path:nth-child(1) {
        fill: #FBCD00;
      }
      path:nth-child(2) {
        fill: #FBCD00;
      }
    }

    .cls-1-casino {
      fill: #C7A100;
    }

    .cls-2-casino {
      fill: #FBCD00;
    }

    .cls-1-inbox {
      fill: #C7A100;
    }

    .cls-2-inbox {
      fill: #FBCD00;
    }

    //Settings
    .cls-1-settings {
      fill: #C7A100;
    }

    .cls-1-favoritescircle {
      fill: #C7A100;
    }


    .cls-2-settings {
      fill: #FBCD00;
    }

    .cls-1-jackpots {
      fill: #C7A100;
    }

    .cls-2-jackpots {
      fill: #FBCD00;
    }

    //Bonus
    .cls-1-bonus {
      fill: #C7A100;
    }

    .cls-2-bonus {
      fill: #FBCD00;
    }

    //My documents
    .cls-1-mydocuments {
      fill: #C7A100;
    }

    .cls-2-mydocuments {
      fill: #FBCD00;
    }

    //Mobile app
    .cls-1-mobileapp {
      fill: #C7A100;
    }

    .cls-2-mobileapp {
      fill: #FBCD00;
    }

    //Cashout
    .cls-1-cashout {
      fill: #C7A100;
    }

    .cls-2-cashout {
      fill: #FBCD00;
    }

    //Vip
    .cls-1-vip {
      fill: #C7A100;
    }

    .cls-2-vip {
      fill: #FBCD00;
    }

    //chat
    .cls-1-chat {
      fill: #456e75;
    }

    .cls-2-chat {
      fill: #96ebff;
    }

    //pin
    .cls-1-pin {
      fill: var(--basic-color);
    }

    .cls-2-pin {
      fill: var(--basic-color);
    }

    //promotions
    .icon-promotions path {
      fill: #f4c601;
    }

    .icon-bet-builder path {
      fill: var(--basic-color);
    }

    .cls-1-boa {
      fill: var(--basic-color);
    }

    .cls-2-boa {
      fill: #fff3be;
    }
  }
}

//NEW APPROACH

.icon-az {
  --icon-fill-1: var(--white);
  --icon-fill-2: var(--body);
}

.scorebox-widgets,
.broadcast-filter {
  .icon-vizu {
    --icon-fill-1: #cbcbd2;
    --icon-fill-2: #79798b;
  }

  .icon-tv,
  .icon-twitch {
    --icon-fill-1: #79798b;
    --icon-fill-2: #cbcbd2;
  }
}

.icon-state-toggle.is-active {
  .icon-vizu {
    --icon-fill-1: var(--basic-color-light);
    --icon-fill-2: var(--basic-color);
  }

  .icon-tv {
    --icon-fill-1: var(--basic-color);
    --icon-fill-2: var(--basic-color-light);
  }

  .icon-twitch {
    --icon-fill-1: var(--basic-color);
    --icon-fill-2: var(--basic-color);
  }

  .icon-info,
  .icon-stats {
    --icon-fill-1: var(--basic-color);
  }

  .icon-star {
    --icon-fill-1: var(--basic-color);
    --icon-stroke-1: var(--basic-color);
  }

  .icon-az {
    --icon-fill-1: var(--basic-color);
  }

  .icon-bookmark {
    --icon-fill-1: var(--basic-color);
    --icon-stroke-1: var(--basic-color);
  }
}

.icon-bookmark.is-active {
  --icon-fill-1: var(--basic-color);
  --icon-stroke-1: var(--basic-color);
}

.icon-star.is-active {
  --icon-fill-1: var(--basic-color);
  --icon-stroke-1: var(--basic-color);
}
