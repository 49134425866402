@import 'sportsbook/mobile-web/shared/layout/bottom-nav';

:root .main-nav {
  --nav-bg: #000;
  --nav-color: #838383;
  --nav-color-active: var(--white);
  --nav-height: 66px;
}

.main-nav-entry__counter {
  --counter-bg: var(--neutral-light-2);
  --counter-color: var(--neutral-dark-1);
}
