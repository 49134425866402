@import 'sportsbook/mobile-web/shared/components/sportsbook';

.game-info-data {
  --data-color: #a3a3a3;
}

.slider__range {
  --slider-range-height: 40px;
  --input-range-height: 15px;
  --input-padding: 0 30px;
  --input-background: var(--primary-color);
  --input-range-radius: 15px;
  --breakpoint-color: #9b9b9b;
  --breakpoint-font-size: 11px;
  --breakpoint-font-weight: 700;
  --active-color: var(--black);
}

.sport-selector {
  --selector-color: var(--neutral-light-3);
  --selector-bg: var(--neutral-dark-1);
  --selector-bg-active: var(--basic-color-dark);
  --selector-color-active: var(--basic-color);
  --selector-radius: 8px;

  &__entry {
    border: var(--border-3);

    &.is-active {
      border-color: var(--selector-bg-active);
    }
  }
}
