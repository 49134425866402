@import 'sportsbook/mobile-web/shared/components/open-game';
:root {
  .sb-open-game,
  .bottom-sheet {
    --live-bg: #3c1b1e;
    --live-color: #ff2424;

    --bg-active: #44380d;
    --color-active: var(--basic-color);

    --market-bg-active: #44380d;
    --market-color-active: var(--basic-color);

    --open-game-radius: 8px;
    --selection-border: 1px solid var(--grey-primary-dark-1);
  }

  .esport .sb-open-game,
  .esport .bottom-sheet {
    --bg-active: #0d3829;
  }
}

.bottom-sheet .selection-entry {
  border-width: 1px;
  background-color: var(--game-bg);
}
