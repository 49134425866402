.games {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;

  &__filter {
    margin-bottom: var(--space-6);
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: var(--space-3);
  }

  &__title {
    margin-bottom: 25px;
    color: var(--game-open-title-color);
    font-size: 18px;
    font-weight: 800;
    letter-spacing: -0.05em;
    @include capitalize;
  }

  &__selected {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  &__iframe {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    flex-grow: 1;

    iframe {
      display: block;
      height: 100%;
      width: 100%;
      flex-grow: 1;
      border-radius: var(--radius-primary);
    }
  }
}

.filter-list {
  overflow: auto;
  display: flex;

  &--providers {
    display: none;
    margin-bottom: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    margin-right: var(--space-3);
    height: 35px;
    padding: 0 var(--space-3);
    background-color: var(--filter-bg, var(--neutral-dark-1));
    color: var(--filter-color, var(--neutral-light-3));
    font-size: 12px;
    font-weight: 700;
    letter-spacing: var(--ls-1);
    white-space: nowrap;
    text-transform: capitalize;
    border: 1px solid var(--filter-border-color, var(--grey-primary-dark-1));
    border-radius: var(--filter-radius, var(--radius-1));
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    &.is-active {
      color: var(--basic-color);
      background-color: var(--basic-color-dark);
      border-color: var(--basic-color-dark);
    }
  }
}

.games-card {
  display: flex;
  flex-flow: column;
  align-items: center;

  &__img {
    width: 100%;
    height: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: var(--card-bg, var(--bg-1));
    border-radius: var(--radius-1);
  }

  &__info {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 5px;
    letter-spacing: var(--ls-2);
  }

  &__title {
    color: var(--name-color, var(--color-1));
    font-size: 12px;
    font-weight: 700;
    @include capitalize;
  }

  &__subtitle {
    color: var(--provider-color, var(--neutral-light-3));
    font-size: 11px;
    font-weight: 600;
    @include capitalize;
  }
}

.vr-info {
  padding: 24px 0;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.02em;
  text-align: center;
}
