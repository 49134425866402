@import 'sportsbook/mobile-web/shared/components/betslip';

.betslip-cp {
  --betslip-bg: var(--ds-neutral-95);
  --bet-bg-trans: rgb(49 49 55 / 95%);
  --boost-color: #ff9e40;
  --won: #289b00;
  --failure: #c90000;
  --return: #e48800;
  --success: var(--basic-color);
  --error: #da1616;
  --betslip-radius: 12px;
}

.sb-bet-status {
  --status-bg: #110f11;
  --share-border-color: var(--body);

  &.is-won {
    --status-color: var(--won);
  }

  &.is-return {
    --status-color: var(--return);
  }

  &.is-lost {
    --status-color: var(--failure);
  }

  &.cashout-available {
    --status-bg: var(--won);
  }

  &.see-more {
    --status-bg: #747483;
  }

  &.cashout-not-available {
    --status-color: #3f3e3f;
  }
}

.sb-sticky-info {
  &--cashout {
    --bg: var(--red);
  }

  &--bonus {
    --bg: #289b00;
  }
}

.betslip-checkbox,
.betslip-blik .csb-checkbox {
  --toggle-bg: #4b4b55;
  --toggle-bg-inside: #fff;
}

.betslip-bet .betslip-checkbox {
  --toggle-bg-active: #93939f;
}

.sb-bet-details {
  --color: #717171;
  --name-color: #9b9b9b;
}

.sb-icon-state {
  --border: 2px solid var(--basic-color);
}

.betslip-heading .btn {
  --border-color: var(--primary-color);
  --border: 2px solid var(--border-color);
  --close-border: var(--basic-color);
  --fill: var(--basic-color);
}

//SETTINGS
.basic-checkbox {
  --checkbox-color-active: var(--basic-color): ;
}

//NAV
.betslip-nav {
  --color-active: var(--ds-brand-60);
  --bg-active: var(--ds-brand-100);
}

.sb-counter {
  --counter-bg: #727272;

  &.cashout-available {
    --counter-bg: var(--primary-green);
  }
}

.betslip-place {
  --place-color-2: #636363;
  --btn-success-bg: var(--primary-green);
}

.betslip-entry {
  --close-fill: #707070;
  --bet-bg-trans: rgb(49 49 55 / 95%);
  --bet-odds-radius: 8px;
  --banker-bg: var(--body);
  --tax-color: #636363;
  &.is-empty {
    display: flex;
    height: 100%;
  }
}

.betslip-empty-alert-icon {
  align-self: center;
}

.indicator-live {
  --live-radius: 8px;
}

.betslip-blik {
  & > div {
    margin: 12px 0;
    padding: 12px;
    background: #232329;
    border-radius: var(--betslip-radius);

    &[hidden],
    & > span {
      display: none;
    }

    .has-blik-inputs {
      margin-bottom: 12px;
    }
  }

  .has-blik-inputs {
    label {
      display: none;
    }

    .underscore-input {
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;

      &-number {
        height: 48px;
        width: 100%;
        min-width: 15px;
        transition: border-color 0.3s;
        background: var(--ds-neutral-100);
        color: var(--white);
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        border: 1px solid var(--ds-neutral-80);
        border-radius: var(--betslip-radius);

        &:not(:placeholder-shown),
        &:focus {
          // border-color: var(--white);
        }
      }
    }
  }

  .has-checkbox {
    display: flex;
    flex-flow: row;
    margin-bottom: 0;

    &::before {
      display: inline-block;
      content: '';
      aspect-ratio: 250 / 160;
      min-width: 54px;
      margin: auto 8px auto 0px;
      background-position: center;
      background-size: cover;
      background-image: url('#{$assets-path}/images/other/payments-v4/blik.png');
      border-radius: 8px;
    }

    .csb-checkbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &-state {
        position: relative;
        height: 22px;
        width: 43px;
        margin: unset;
        background: var(--toggle-bg);
        border: unset;
        border-radius: 20px;
        transition: background-color 200ms ease-in-out;

        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          opacity: unset;
          top: 50%;
          height: 18px;
          width: 18px;
          transition: transform 0.2s ease-in-out;
          background: var(--toggle-bg-inside);
          border-radius: 50%;
          transform: translate(2px, -50%);
        }
      }

      &[aria-checked='true'] {
        .csb-checkbox-state {
          background-color: var(--toggle-bg-active, var(--basic-color));

          &::after {
            transform: translate(23px, -50%);
          }
        }
      }

      &-title {
        order: -1;
        color: var(--ds-neutral-50);
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.02em;
        ::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  .csb-modal-error {
    margin: 0;
    margin-top: 5px;
    color: var(--ds-error-50);
    font-size: 12px;
    font-weight: 600;
  }
}

.betslip-summary__col {
  &--winnings {
    flex-flow: column;
    gap: var(--ds-space-1);
  }

  &--winnings-title {
    align-items: flex-start;
  }

  &--winnings-value {
    align-items: flex-end;
  }

  .betslip-summery__title-container {
    display: flex;
    align-items: center;
    gap: var(--ds-space-2);
  }

  .betslip-summary__data--ellipsis {
    display: block;
    width: 100%;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
