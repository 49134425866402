:root {
  // General

  --basic-color-always: #fbcb00;
  --basic-color-transparent: #9dbdef;
  --basic-color-light: #fff3be;
  --body: #110f11;
  --body-bg: #110f11;
  --body-rgb: 17, 15, 17;
  --primary-color: #313137;
  --bg-primary: #313137;
  --second-color: #93b1bf;
  --second-color-light: #b4cbd7;
  --primary-green: #00b372;
  --bg-green: #00b372;
  --primary-red: #f10;
  --bg-red-2: #f00000;
  --primary-orange: #e48800;
  --bg-brand: #fbcb00;
  --brand-50: #ffd72e;
  --active-dropdown-button: #000;
  --active-dropdown-button-bg: #fbcb00;
  --registration-info: #cc7a0026;
  --registration-info-button: #ffffff1a;

  .affiliate {
    --bg: var(--basic-color);
  }

  //active link color
  --active-color: #44380d;

  // Background
  --white: #fff;
  --white-darker: #f8f8ff;
  --black: #000;
  --black-2: #110f11;
  --transparent: transparent;
  --grey: #515a5e;
  --grey-2: #c8d6dc;
  --grey-3: #7d939e;
  --grey-4: #dfe6e9;
  --grey-5: #adadad;
  --grey-6: #727272;
  --grey-7: #191920;
  --red: #da1616;
  --red-2: #a90101;
  --red-3: #ad0000;
  --red-4: #cd0000;
  --green-2: #0e9800;
  --green-3: #8aff00;
  --blue: #006fb5;
  --bg-body: #110f11;

  // Font color
  --f-grey: #b7b7c2;
  --f-grey-2: #a3a3a3;
  --f-grey-3: #7a7a80;
  --f-grey-4: #a7acaf;
  --f-grey-5: #686868;
  --f-grey-6: #b6b6b6;
  --f-grey-7: #93b1bf;
  --f-grey-8: #515a5e;
  --f-grey-9: #3c3c3c;
  --f-grey-10: #a9aeb1;
  --f-grey-11: #c1c1c1;
  --f-grey-12: #636363;
  --f-grey-13: #838383;
  --f-grey-14: #717171;
  --f-grey-15: #9b9b9b;
  --f-grey-16: #acacac;
  --f-grey-17: #b1b1b1;
  --f-grey-18: #8c8c8f;
  --f-black-2: #1b1a1d;
  --f-grey-19: #1b1b1f;
  --f-grey-20: #222227;
  --f-grey-21: #b0b0ba;
  --f-grey-22: #9595a2;
  --f-grey-23: #a7a7a7;
  --f-grey-24: #58585b;
  --f-grey-25: #585860;
  --f-grey-26: #414147;
  --f-grey-27: #cecece;

  --f-white-2: #cecece;

  // Border radius
  --radius-primary: 10px;
  --radius-second: 20px;
  --radius-circle: 50%;
  --radius-xs: 3px;

  // Neutral
  --neutral-10: #e6e6ea;
  --neutral-20: #cbcbd2;
  --neutral-30: #b0b0ba;
  --neutral-40: #9595a2;
  --neutral-50: #79798b;
  --neutral-60: #616170;
  --neutral-70: #4a4a55;
  --neutral-80: #323239;
  --neutral-85: #323239;
  --neutral-90: #1b1b1f;
  --neutral-100: #0e0e10;

  //COLOR BRAND
  --brand-10: #fffefa;
  --brand-20: #fff4c7;
  --brand-30: #ffeb94;
  --brand-40: #ffe161;
  --brand-50: #ffd72e;
  --brand-60: #fbcd00;
  --brand-70: #c7a100;
  --brand-80: #947800;
  --brand-90: #614e00;
  --brand-100: hsl(49deg, 100%, 49.22%, 0.2);

  // Toggles

  /* stylelint-disable -- need this to top header works */
  --top-space-height: 0px;
  --bottom-space-height: 0px;
  /* stylelint-enable */

  // Ios safe area
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-bottom: env(safe-area-inset-bottom);
  --safe-area-left: env(safe-area-inset-left);
  --safe-area-right: env(safe-area-inset-right);
  --container-side-gutter: 10px;
  --container-side-padding: 10px;
  --container-max-width: 800px;

  // Dynamin variables
  --body-side-gutter: 14px;
  --basic-color: #fbcb00;
  --basic-color-disabled: #7e6600;
  --basic-color-dark: #44380d;
  --color-1: #fff;
  --color-2: #b7b7c2;
  --color-3: #c1c1c1;
  --color-4: #a3a3a3;
  --bg-1: #313137;
  --bg-primary: var(--bg-1);
  --red-1: #f10;
  --fill-1: #fff;
  --fill-2: #313137;
  --fill-3: #b7b7c2;
  --fill-4: #000;
  --fill-brand: var(--basic-color);
  --fill-active-1: #000;
  --border-1: 2px solid #313137;
  --border-2: 2px solid var(--basic-color);
  --border-3: 1px solid #222227;
  --border-4: 2px solid #ff9e40;
  --bg-install: #00b372;
  --install-color: #fff;

  --radius-1: 10px;
  --radius-2: 8px;
  --ls-1: -0.025em;
  --ls-2: -0.05em;
  --odds-increase: #00b372;
  --odds-decrease: #f10;

  //RADIUSES
  --radius-xs: 8px;
  --radius-s: 12px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  //Live
  --live-bg: #3c1b1e;
  --live-color: #ff2424;

  /*Desing system */
  --neutral-light-2: #cbcbd2;
  --neutral-light-3: #b0b0ba;

  --neutral-dark-0: #16161a;
  --neutral-dark-1: #1b1b1f;
  --neutral-dark-2: #323239;
  --neutral-dark-3: #4a4a55;

  --grey-primary: #313137;
  --grey-primary-dark-1: #222227;

  /*Desing system spacing*/

  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 20px;
  --space-6: 24px;
  --space-7: 32px;
  --space-8: 40px;
  --space-9: 48px;
  --space-10: 56px;
  --space-11: 64px;
  --space-12: 80px;
  --space-13: 96px;
  --space-14: 128px;
  --space-15: 160px;
  --space-16: 192px;
  --space-17: 224px;
  --space-18: 256px;
  --space-19: 512px;

  //RADIUSES
  --radius-xs: 8px;
  --radius-s: 12px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  body.esport,
  .esport-theme {
    &:not(.betslip-opened) {
      --basic-color: var(--ds-success-60);
      --basic-color-dark: var(--ds-success-60);
      --ds-brand-60: var(--ds-success-60);
      --ds-brand-100: var(--ds-success-100);
    }

    .header-bar {
      --bg-color: #0e2c1e;
    }

    .betslip {
      --basic-color: #fbcb00;
    }
  }

  .side-menu {
    // //margin
    // --m-1: 10px;
    // --m-2: 20px;

    // //padding
    // --p-1: 10px;
    // --p-2: 21px;
    // --p-3: 23px;
    // --p-4: 15px;
    // --p-5: 18px;

    //radiuses
    --radius-1: 12px;
  }

  .right-menu {
    // --m-1: 10px;
    // --m-2: 15px;
    // --m-3: 22px;
    // --m-4: 30px;
    // --m-5: 40px;

    // --p-1: 10px;
    // --p-2: 15px;

    --radius-1: 12px;
    --radius-2: 20px;
    --radius-3: 4px;
  }

  //COLOR BASIC
  --white: #fff;
  --black: #000;

  //COLOR NEUTRAL
  --neutral-10: #e6e6ea;
  --neutral-20: #cbcbd2;
  --neutral-30: #b0b0ba;
  --neutral-40: #9595a2;
  --neutral-50: #79798b;
  --neutral-60: #616170;
  --neutral-70: #4a4a55;
  --neutral-80: #323239;
  --neutral-85: #27272d;
  --neutral-90: #1b1b1f;
  --neutral-100: #0e0e10;

  //COLOR TRANSPARENT
  --transparent-10: hsl(0deg, 0%, 100%, 0.9);
  --transparent-20: hsl(0deg, 0%, 100%, 0.8);
  --transparent-30: hsl(0deg, 0%, 100%, 0.7);
  --transparent-40: hsl(0deg, 0%, 100%, 0.6);
  --transparent-50: hsl(0deg, 0%, 100%, 0.5);
  --transparent-60: hsl(0deg, 0%, 100%, 0.4);
  --transparent-70: hsl(0deg, 0%, 100%, 0.3);
  --transparent-80: hsl(0deg, 0%, 100%, 0.2);
  --transparent-90: hsl(0deg, 0%, 100%, 0.1);
  --transparent-100: hsl(0deg, 0%, 100%, 0);

  //COLOR BRAND
  --brand-10: #fffefa;
  --brand-20: #fff4c7;
  --brand-30: #ffeb94;
  --brand-40: #ffe161;
  --brand-50: #ffd72e;
  --brand-60: #fbcd00;
  --brand-70: #c7a100;
  --brand-80: #947800;
  --brand-90: #614e00;
  --brand-100: hsl(49deg, 100%, 49.22%, 0.2);

  //COLOR SUCCESS
  --success-10: #e5fff6;
  --success-20: #b3ffe3;
  --success-30: #80ffd1;
  --success-40: #4dffbe;
  --success-50: #1affac;
  --success-60: #00e592;
  --success-70: #00b372;
  --success-80: #008051;
  --success-90: #004d31;
  --success-100: hsl(158.25deg, 100%, 44.9%, 0.15);

  //COLOR INFO
  --info-10: #ccebff;
  --info-20: #99d7ff;
  --info-30: #66c4ff;
  --info-40: #33b0ff;
  --info-50: #009cff;
  --info-60: #007dcc;
  --info-70: #005e99;
  --info-80: #003e66;
  --info-90: #001f33;
  --info-100: hsla(203, 100%, 40%, 0.15);

  //COLOR WARNING
  --warning-10: #ffeacc;
  --warning-20: #ffd699;
  --warning-30: #ffc166;
  --warning-40: #ffad33;
  --warning-50: #ff9800;
  --warning-60: #cc7a00;
  --warning-70: #995b00;
  --warning-80: #663d00;
  --warning-90: #331f00;
  --warning-100: hsla(36, 100%, 40%, 0.15);

  //COLOR ERROR
  --error-10: #fff0f0;
  --error-20: #ffbdbd;
  --error-30: #ff8a8a;
  --error-40: #ff5757;
  --error-50: #ff2424;
  --error-60: #f00000;
  --error-70: #bd0000;
  --error-80: #8a0000;
  --error-90: #570000;
  --error-100: hsla(0, 100%, 47%, 0.15);

  //COLOR ESPORT
  --esport-10: #ccffe7;
  --esport-20: #99ffce;
  --esport-30: #66ffb6;
  --esport-40: #33ff9d;
  --esport-50: #01ff85;
  --esport-60: #00cc6a;
  --esport-70: #009950;
  --esport-80: #006635;
  --esport-90: #00331b;
  --esport-100: hsla(151, 100%, 40%, 0.15);

  //RADIUSES
  --radius-xs: 8px;
  --radius-s: 12px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  //SPACING
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 20px;
  --space-6: 24px;
  --space-7: 32px;
  --space-8: 40px;
  --space-9: 48px;
  --space-10: 56px;
  --space-11: 64px;
  --space-12: 80px;
  --space-13: 96px;
  --space-14: 128px;
  --space-15: 160px;
  --space-16: 192px;
  --space-17: 224px;
  --space-18: 256px;
  --space-19: 512px;

  //IOS
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-bottom: env(safe-area-inset-bottom);
  --safe-area-left: env(safe-area-inset-left);
  --safe-area-right: env(safe-area-inset-right);

  // BRANDS COLORS
  --bg-blue-facebook: #1976d2;
  --bg-twitter: #000;
  --bg-blue-instagram: #5258cf;
  --bg-blue-telegram: #28a8e8;

  // BORDER COLORS
  --line-primary: #313137;
}
