@import 'sportsbook/mobile-web/shared/components/fast-bet';

.betslip-btn-container {
  //general fastbet colors
  --fb-bg: var(--black);

  //betslip btn colors
  --betslip-counter-bg: var(--red);
  --betslip-counter-color: var(--white);

  //bet colors
  --fb-color-2: #838383;

  //bet status colors
  --status-color: var(--white);

  .is-processing {
    --status-color: var(--basic-color);
  }

  .is-canceled {
    --status-color: var(--primary-red);
  }

  .is-placed {
    --status-color: var(--primary-green);
  }
}