@import './fonts';

.subpages,
.promotion-open {
  ul,
  ol {
    list-style: none;
    counter-reset: item;

    li {
      position: relative;
      padding-left: 20px;
      counter-increment: item;

      &:before {
        content: counter(item, decimal);
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        font-weight: 800;
        transition: color 0.3s;
      }

      ul,
      ol {
        list-style: none;
        counter-reset: item;
        padding: 20px 0 0 0;

        li {
          margin-bottom: 20px;
          padding-left: 20px;

          &:before {
            content: counter(item, upper-alpha);
          }

          ul,
          ol {
            padding: 10px 0 0 0;
          }

          li {
            &:before {
              content: counter(item, upper-roman);
            }
          }
        }
      }
    }
  }
}

button {
  font-family: inherit;
}

.text-basic {
  font-size: 13px;
  letter-spacing: -0.05em;
}

.text-m-size {
  font-size: 15px;
  letter-spacing: -0.05em;
}

.text-grey {
  color: var(--grey);
}

.text-white {
  color: var(--white);
}

.text-grey,
.text-white {
  span,
  a {
    color: var(--basic-color);
  }
}

.success-state {
  color: var(--primary-green);
}

.error-state {
  color: var(--primary-red);
}
