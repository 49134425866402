.sb-icon {
  background-repeat: no-repeat;
  background-position: center;

  &-logo {
    background-image: url('/img/logo.svg');

    &--big {
      width: 177px;
    }
  }
}

/* === COUNTRIES ICONS === */
.img-icon.great-britain,
.flag-ac {
  background-image: url('#{$assets-path}/images/countries/great-britain.svg');
}

.afghanistan,
.flag-af {
  background-image: url('#{$assets-path}/images/countries/afghanistan.svg');
}

.africa {
  background-image: url('#{#{$assets-path}}/images/countries/africa.svg');
}

.aland-islands,
.flag-ax {
  background-image: url('#{$assets-path}/images/countries/aland-islands.svg');
}

.albania,
.flag-al {
  background-image: url('#{$assets-path}/images/countries/albania.svg');
}

.algeria,
.flag-dz {
  background-image: url('#{$assets-path}/images/countries/algeria.svg');
}

.america {
  background-image: url('#{$assets-path}/images/countries/america.svg');
}

.american-samoa,
.flag-as {
  background-image: url('#{$assets-path}/images/countries/american-samoa.svg');
}

.andorra,
.flag-ad {
  background-image: url('#{$assets-path}/images/countries/andorra.svg');
}

.angola,
.flag-ao {
  background-image: url('#{$assets-path}/images/countries/angola.svg');
}

.anguilla,
.flag-ai {
  background-image: url('#{$assets-path}/images/countries/anguilla.svg');
}

.antigua-and-barbuda,
.flag-ag {
  background-image: url('#{$assets-path}/images/countries/antigua-and-barbuda.svg');
}

.argentina,
.flag-ar {
  background-image: url('#{$assets-path}/images/countries/argentina.svg');
}

.armenia,
.flag-am {
  background-image: url('#{$assets-path}/images/countries/armenia.svg');
}

.aruba,
.flag-aw {
  background-image: url('#{$assets-path}/images/countries/aruba.svg');
}

.asia {
  background-image: url('#{$assets-path}/images/countries/asia.svg');
}

.australia,
.flag-au {
  background-image: url('#{$assets-path}/images/countries/australia.svg');
}

.austria,
.flag-at {
  background-image: url('#{$assets-path}/images/countries/austria.svg');
}

.azerbaijan,
.flag-az {
  background-image: url('#{$assets-path}/images/countries/azerbaijan.svg');
}

.bahamas,
.flag-bs {
  background-image: url('#{$assets-path}/images/countries/bahamas.svg');
}

.bahrain,
.flag-bh {
  background-image: url('#{$assets-path}/images/countries/bahrain.svg');
}

.bangladesh,
.flag-bd {
  background-image: url('#{$assets-path}/images/countries/bangladesh.svg');
}

.barbados,
.flag-bb {
  background-image: url('#{$assets-path}/images/countries/barbados.svg');
}

.belarus,
.flag-by {
  background-image: url('#{$assets-path}/images/countries/belarus.svg');
}

.belgium,
.flag-be {
  background-image: url('#{$assets-path}/images/countries/belgium.svg');
}

.belize,
.flag-bz {
  background-image: url('#{$assets-path}/images/countries/belize.svg');
}

.benin,
.flag-bj {
  background-image: url('#{$assets-path}/images/countries/benin.svg');
}

.bermuda,
.flag-bm {
  background-image: url('#{$assets-path}/images/countries/bermuda.svg');
}

.bhutan,
.flag-bt {
  background-image: url('#{$assets-path}/images/countries/bhutan.svg');
}

.bolivia,
.flag-bo {
  background-image: url('#{$assets-path}/images/countries/bolivia.svg');
}

.bonaire-saint-eustatius-and-saba {
  background-image: url('#{$assets-path}/images/countries/bonaire-saint-eustatius-and-saba.svg');
}

.bosnia-and-herzegovina,
.flag-ba {
  background-image: url('#{$assets-path}/images/countries/bosnia-and-herzegovina.svg');
}

.botswana,
.flag-bw {
  background-image: url('#{$assets-path}/images/countries/botswana.svg');
}

.bouvet-island,
.flag-bv {
  background-image: url('#{$assets-path}/images/countries/bouvet-island.svg');
}

.brazil,
.brasil,
.flag-br {
  background-image: url('#{$assets-path}/images/countries/brazil.svg');
}

.british-indian-ocean-territory,
.flag-io {
  background-image: url('#{$assets-path}/images/countries/british-indian-ocean-territory.svg');
}

.brunei-darussalam,
.flag-bn {
  background-image: url('#{$assets-path}/images/countries/brunei-darussalam.svg');
}

.bulgaria,
.flag-bg {
  background-image: url('#{$assets-path}/images/countries/bulgaria.svg');
}

.burkina-faso,
.flag-bf {
  background-image: url('#{$assets-path}/images/countries/burkina-faso.svg');
}

.burundi,
.flag-bi {
  background-image: url('#{$assets-path}/images/countries/burundi.svg');
}

.cambodia,
.flag-kh {
  background-image: url('#{$assets-path}/images/countries/cambodia.svg');
}

.cameroon,
.flag-cm {
  background-image: url('#{$assets-path}/images/countries/cameroon.svg');
}

.canada,
.flag-ca {
  background-image: url('#{$assets-path}/images/countries/canada.svg');
}

.cape-verde,
.flag-cv {
  background-image: url('#{$assets-path}/images/countries/cape-verde.svg');
}

.carribean-netherlands,
.flag-bq {
  background-image: url('#{$assets-path}/images/countries/netherlands.svg');
}

.cayman-islands,
.flag-ky {
  background-image: url('#{$assets-path}/images/countries/cayman-islands.svg');
}

.central-african-republic,
.flag-cf {
  background-image: url('#{$assets-path}/images/countries/central-african-republic.svg');
}

.chad,
.flag-td {
  background-image: url('#{$assets-path}/images/countries/chad.svg');
}

.chile,
.flag-cl {
  background-image: url('#{$assets-path}/images/countries/chile.svg');
}

.china,
.flag-cn {
  background-image: url('#{$assets-path}/images/countries/china.svg');
}

.chinese-taipei {
  background-image: url('#{$assets-path}/images/countries/chinese-taipei.svg');
}

.christmas-island,
.flag-cx {
  background-image: url('#{$assets-path}/images/countries/christmas-island.svg');
}

.cocos-keeling-islands,
.flag-cc {
  background-image: url('#{$assets-path}/images/countries/cocos-keeling-islands.svg');
}

.colombia,
.flag-co {
  background-image: url('#{$assets-path}/images/countries/colombia.svg');
}

.comoros,
.flag-km {
  background-image: url('#{$assets-path}/images/countries/comoros.svg');
}

.congo,
.flag-cd,
.flag-cg {
  background-image: url('#{$assets-path}/images/countries/congo.svg');
}

.cook-islands,
.flag-ck {
  background-image: url('#{$assets-path}/images/countries/cook-islands.svg');
}

.costa-rica,
.flag-cr {
  background-image: url('#{$assets-path}/images/countries/costa-rica.svg');
}

.cote-d-ivoire,
.flag-ci {
  background-image: url('#{$assets-path}/images/countries/cote-d-ivoire.svg');
}

.croatia,
.flag-hr {
  background-image: url('#{$assets-path}/images/countries/croatia.svg');
}

.cuba,
.flag-cu {
  background-image: url('#{$assets-path}/images/countries/cuba.svg');
}

.curacao,
.flag-cw {
  background-image: url('#{$assets-path}/images/countries/curacao.svg');
}

.cyprus,
.flag-cy {
  background-image: url('#{$assets-path}/images/countries/cyprus.svg');
}

.czech-republic,
.flag-cz {
  background-image: url('#{$assets-path}/images/countries/czech_republic.svg');
}

.denmark,
.flag-dk {
  background-image: url('#{$assets-path}/images/countries/denmark.svg');
}

.djibouti,
.flag-dj {
  background-image: url('#{$assets-path}/images/countries/djibouti.svg');
}

.dominica,
.flag-dm {
  background-image: url('#{$assets-path}/images/countries/dominica.svg');
}

.dominican-republic,
.flag-do {
  background-image: url('#{$assets-path}/images/countries/dominican_republic.svg');
}

.dutch-east-indies {
  background-image: url('#{$assets-path}/images/countries/dutch-east-indies.svg');
}

.east-germany {
  background-image: url('#{$assets-path}/images/countries/east-germany.svg');
}

.ecuador,
.flag-ec {
  background-image: url('#{$assets-path}/images/countries/ecuador.svg');
}

.egypt,
.flag-eg {
  background-image: url('#{$assets-path}/images/countries/egypt.svg');
}

.el-salvador,
.flag-sv {
  background-image: url('#{$assets-path}/images/countries/el-salvador.svg');
}

.england {
  background-image: url('#{$assets-path}/images/countries/england.svg');
}

.equatorial-guinea,
.flag-gq {
  background-image: url('#{$assets-path}/images/countries/equatorial-guinea.svg');
}

.eritrea,
.flag-er {
  background-image: url('#{$assets-path}/images/countries/eritrea.svg');
}

.estonia,
.flag-ee {
  background-image: url('#{$assets-path}/images/countries/estonia.svg');
}

.ethiopia,
.flag-et {
  background-image: url('#{$assets-path}/images/countries/ethiopia.svg');
}

.europe {
  background-image: url('#{$assets-path}/images/countries/europe.svg');
}

.falkland-islands-malvinas,
.flag-fk {
  background-image: url('#{$assets-path}/images/countries/falkland-islands-malvinas.svg');
}

.faroe-islands,
.flag-fo {
  background-image: url('#{$assets-path}/images/countries/faroe-islands.svg');
}

.federated-states-of-micronesia,
.flag-fm {
  background-image: url('#{$assets-path}/images/countries/federated-states-of-micronesia.svg');
}

.fiji,
.flag-fj {
  background-image: url('#{$assets-path}/images/countries/fiji.svg');
}

.fi,
.finland,
.flag-fi {
  background-image: url('#{$assets-path}/images/countries/finland.svg');
}

.fr,
.france,
.flag-fr {
  background-image: url('#{$assets-path}/images/countries/france.svg');
}

.french-guiana,
.flag-gf {
  background-image: url('#{$assets-path}/images/countries/french-guiana.svg');
}

.french-polynesia,
.flag-pf {
  background-image: url('#{$assets-path}/images/countries/french-polynesia.svg');
}

.french-southern-territories {
  background-image: url('#{$assets-path}/images/countries/french-southern-territories.svg');
}

.gabon,
.flag-ga {
  background-image: url('#{$assets-path}/images/countries/gabon.svg');
}

.gambia,
.flag-gm {
  background-image: url('#{$assets-path}/images/countries/gambia.svg');
}

.georgia,
.flag-ge {
  background-image: url('#{$assets-path}/images/countries/georgia.svg');
}

.germany,
.flag-de {
  background-image: url('#{$assets-path}/images/countries/germany.svg');
}

.ghana,
.flag-gh {
  background-image: url('#{$assets-path}/images/countries/ghana.svg');
}

.gibraltar,
.flag-gi {
  background-image: url('#{$assets-path}/images/countries/gibraltar.svg');
}

.great-britain {
  background-image: url('#{$assets-path}/images/countries/great-britain.svg');
}

.greece,
.flag-gr {
  background-image: url('#{$assets-path}/images/countries/greece.svg');
}

.greenland,
.flag-gl {
  background-image: url('#{$assets-path}/images/countries/greenland.svg');
}

.grenada,
.flag-gd {
  background-image: url('#{$assets-path}/images/countries/grenada.svg');
}

.guadeloupe,
.flag-gp {
  background-image: url('#{$assets-path}/images/countries/guadeloupe.svg');
}

.guam,
.flag-gu {
  background-image: url('#{$assets-path}/images/countries/guam.svg');
}

.guatemala,
.flag-gt {
  background-image: url('#{$assets-path}/images/countries/guatemala.svg');
}

.guernsey,
.flag-gg {
  background-image: url('#{$assets-path}/images/countries/guernsey.svg');
}

.guinea,
.flag-gn {
  background-image: url('#{$assets-path}/images/countries/guinea.svg');
}

.guinea-bissau,
.flag-gw {
  background-image: url('#{$assets-path}/images/countries/guinea-bissau.svg');
}

.guyana,
.flag-gy {
  background-image: url('#{$assets-path}/images/countries/guyana.svg');
}

.haiti,
.flag-ht {
  background-image: url('#{$assets-path}/images/countries/haiti.svg');
}

.heard-island-and-mcdonald-islands,
.flag-hm {
  background-image: url('#{$assets-path}/images/countries/heard-island-and-mcdonald-islands.svg');
}

.holy-see-vatican-city-state {
  background-image: url('#{$assets-path}/images/countries/holy-see-vatican-city-state.svg');
}

.honduras,
.flag-hn {
  background-image: url('#{$assets-path}/images/countries/honduras.svg');
}

.hong-kong,
.flag-hk {
  background-image: url('#{$assets-path}/images/countries/hong-kong.svg');
}

.hungary,
.flag-hu {
  background-image: url('#{$assets-path}/images/countries/hungary.svg');
}

.iceland,
.flag-is {
  background-image: url('#{$assets-path}/images/countries/iceland.svg');
}

.india,
.flag-in {
  background-image: url('#{$assets-path}/images/countries/india.svg');
}

.indonesia,
.flag-id {
  background-image: url('#{$assets-path}/images/countries/indonesia.svg');
}

.iran,
.flag-ir {
  background-image: url('#{$assets-path}/images/countries/iran.svg');
}

.iraq,
.flag-iq {
  background-image: url('#{$assets-path}/images/countries/iraq.svg');
}

.isle-of-man,
.flag-im {
  background-image: url('#{$assets-path}/images/countries/isle-of-man.svg');
}

.israel,
.flag-il {
  background-image: url('#{$assets-path}/images/countries/israel.svg');
}

.ireland,
.flag-ie {
  background-image: url('#{$assets-path}/images/countries/ireland.svg');
}

.italy,
.flag-it {
  background-image: url('#{$assets-path}/images/countries/italy.svg');
}

.jamaica,
.flag-jm {
  background-image: url('#{$assets-path}/images/countries/jamaica.svg');
}

.japan,
.flag-jp {
  background-image: url('#{$assets-path}/images/countries/japan.svg');
}

.jersey,
.flag-je {
  background-image: url('#{$assets-path}/images/countries/jersey.svg');
}

.jordan,
.flag-jo {
  background-image: url('#{$assets-path}/images/countries/jordan.svg');
}

.kazakhstan,
.flag-kz {
  background-image: url('#{$assets-path}/images/countries/kazakhstan.svg');
}

.kenya,
.flag-ke {
  background-image: url('#{$assets-path}/images/countries/kenya.svg');
}

.kiribati,
.flag-ki {
  background-image: url('#{$assets-path}/images/countries/kiribati.svg');
}

.korea-north,
.flag-kp {
  background-image: url('#{$assets-path}/images/countries/korea_north.svg');
}

.korea-south,
.korea-rep,
.flag-kr {
  background-image: url('#{$assets-path}/images/countries/korea-south.svg');
}

.kosovo,
.flag-xk {
  background-image: url('#{$assets-path}/images/countries/kosovo.svg');
}

.kuwait,
.flag-kw {
  background-image: url('#{$assets-path}/images/countries/kuwait.svg');
}

.kyrgyzstan,
.flag-kg {
  background-image: url('#{$assets-path}/images/countries/kyrgyzstan.svg');
}

.sb-icon.lao-people-s-democratic-republic,
.sb-icon.laos,
.flag-la {
  background-image: url('#{$assets-path}/images/countries/lao-people-s-democratic-republic.svg');
}

.latvia,
.flag-lv {
  background-image: url('#{$assets-path}/images/countries/latvia.svg');
}

.lebanon,
.flag-lb {
  background-image: url('#{$assets-path}/images/countries/lebanon.svg');
}

.lesotho,
.flag-ls {
  background-image: url('#{$assets-path}/images/countries/lesotho.svg');
}

.liberia,
.flag-lr {
  background-image: url('#{$assets-path}/images/countries/liberia.svg');
}

.libyan-arab-jamahiriya,
.flag-ly {
  background-image: url('#{$assets-path}/images/countries/libyan-arab-jamahiriya.svg');
}

.liechtenstein,
.flag-li {
  background-image: url('#{$assets-path}/images/countries/liechtenstein.svg');
}

.lithuania,
.flag-lt {
  background-image: url('#{$assets-path}/images/countries/lithuania.svg');
}

.luxembourg,
.flag-lu {
  background-image: url('#{$assets-path}/images/countries/luxembourg.svg');
}

.macau,
.flag-mo {
  background-image: url('#{$assets-path}/images/countries/macao.svg');
}

.macedonia,
.flag-mk {
  background-image: url('#{$assets-path}/images/countries/macedonia.svg');
}

.madagascar,
.flag-mg {
  background-image: url('#{$assets-path}/images/countries/madagascar.svg');
}

.malawi,
.flag-mw {
  background-image: url('#{$assets-path}/images/countries/malawi.svg');
}

.malaysia,
.flag-my {
  background-image: url('#{$assets-path}/images/countries/malaysia.svg');
}

.maldives,
.flag-mv {
  background-image: url('#{$assets-path}/images/countries/maldives.svg');
}

.mali,
.flag-ml {
  background-image: url('#{$assets-path}/images/countries/mali.svg');
}

.malta,
.flag-mt {
  background-image: url('#{$assets-path}/images/countries/malta.svg');
}

.marshall-islands,
.flag-mh {
  background-image: url('#{$assets-path}/images/countries/marshall-islands.svg');
}

.martinique,
.flag-mq {
  background-image: url('#{$assets-path}/images/countries/martinique.svg');
}

.mauritania,
.flag-mr {
  background-image: url('#{$assets-path}/images/countries/mauritania.svg');
}

.mauritius,
.flag-mu {
  background-image: url('#{$assets-path}/images/countries/mauritius.svg');
}

.mayotte,
.flag-yt {
  background-image: url('#{$assets-path}/images/countries/mayotte.svg');
}

.mexico,
.flag-mx {
  background-image: url('#{$assets-path}/images/countries/mexico.svg');
}

.moldova,
.flag-md {
  background-image: url('#{$assets-path}/images/countries/moldova.svg');
}

.monaco,
.flag-mc {
  background-image: url('#{$assets-path}/images/countries/monaco.svg');
}

.mongolia,
.flag-mn {
  background-image: url('#{$assets-path}/images/countries/mongolia.svg');
}

.montenegro,
.flag-me {
  background-image: url('#{$assets-path}/images/countries/montenegro.svg');
}

.montserrat,
.flag-ms {
  background-image: url('#{$assets-path}/images/countries/montserrat.svg');
}

.morocco,
.flag-ma {
  background-image: url('#{$assets-path}/images/countries/morocco.svg');
}

.mozambique,
.flag-mz {
  background-image: url('#{$assets-path}/images/countries/mozambique.svg');
}

.myanmar,
.flag-mm {
  background-image: url('#{$assets-path}/images/countries/myanmar.svg');
}

.namibia,
.flag-na {
  background-image: url('#{$assets-path}/images/countries/namibia.svg');
}

.nambia {
  background-image: url('#{$assets-path}/images/countries/nambia.svg');
}

.nauru,
.flag-nr {
  background-image: url('#{$assets-path}/images/countries/nauru.svg');
}

.nepal,
.flag-np {
  background-image: url('#{$assets-path}/images/countries/nepal.svg');
}

.netherlands,
.flag-nl {
  background-image: url('#{$assets-path}/images/countries/netherlands.svg');
}

.netherlands-antilles {
  background-image: url('#{$assets-path}/images/countries/netherlands-antilles.svg');
}

.new-caledonia,
.flag-nc {
  background-image: url('#{$assets-path}/images/countries/new-caledonia.svg');
}

.new-zealand,
.flag-nz {
  background-image: url('#{$assets-path}/images/countries/new-zealand.svg');
}

.nicaragua,
.flag-ni {
  background-image: url('#{$assets-path}/images/countries/nicaragua.svg');
}

.niger,
.flag-ne {
  background-image: url('#{$assets-path}/images/countries/niger.svg');
}

.nigeria,
.flag-ng {
  background-image: url('#{$assets-path}/images/countries/nigeria.svg');
}

.niue,
.flag-nu {
  background-image: url('#{$assets-path}/images/countries/niue.svg');
}

.norfolk-island,
.flag-nf {
  background-image: url('#{$assets-path}/images/countries/norfolk-island.svg');
}

.northern-ireland {
  background-image: url('#{$assets-path}/images/countries/northern-ireland.svg');
}

.northern-mariana-islands,
.flag-mp {
  background-image: url('#{$assets-path}/images/countries/northern-mariana-islands.svg');
}

.north-america {
  background-image: url('#{$assets-path}/images/countries/north-america.svg');
}

.norway,
.flag-no {
  background-image: url('#{$assets-path}/images/countries/norway.svg');
}

.oceania {
  background-image: url('#{$assets-path}/images/countries/oceania.svg');
}

.oman,
.flag-om {
  background-image: url('#{$assets-path}/images/countries/oman.svg');
}

.pakistan,
.flag-pk {
  background-image: url('#{$assets-path}/images/countries/pakistan.svg');
}

.palau,
.flag-pw {
  background-image: url('#{$assets-path}/images/countries/palau.svg');
}

.palestinian-territory-occupied,
.palestine,
.flag-ps {
  background-image: url('#{$assets-path}/images/countries/palestinian-territory-occupied.svg');
}

.panama,
.flag-pa {
  background-image: url('#{$assets-path}/images/countries/panama.svg');
}

.papua-new-guinea,
.flag-pg {
  background-image: url('#{$assets-path}/images/countries/papua-new-guinea.svg');
}

.paraguay,
.flag-py {
  background-image: url('#{$assets-path}/images/countries/paraguay.svg');
}

.peru,
.flag-pe {
  background-image: url('#{$assets-path}/images/countries/peru.svg');
}

.philippines,
.flag-ph {
  background-image: url('#{$assets-path}/images/countries/philippines.svg');
}

.pitcairn,
.flag-pn {
  background-image: url('#{$assets-path}/images/countries/pitcairn.svg');
}

.poland,
.flag-pl {
  background-image: url('#{$assets-path}/images/countries/poland.svg');
}

.portugal,
.flag-pt {
  background-image: url('#{$assets-path}/images/countries/portugal.svg');
}

.puerto-rico,
.flag-pr {
  background-image: url('#{$assets-path}/images/countries/puerto-rico.svg');
}

.qatar,
.flag-qa {
  background-image: url('#{$assets-path}/images/countries/qatar.svg');
}

.republic-of-ireland {
  background-image: url('#{$assets-path}/images/countries/republic-of-ireland.svg');
}

.reunion,
.flag-re {
  background-image: url('#{$assets-path}/images/countries/reunion.svg');
}

.romania,
.flag-ro {
  background-image: url('#{$assets-path}/images/countries/romania.svg');
}

.russia,
.russian-federation,
.flag-ru {
  background-image: url('#{$assets-path}/images/countries/russia.svg');
}

.rwanda,
.flag-rw {
  background-image: url('#{$assets-path}/images/countries/rwanda.svg');
}

.saint-barthelemy,
.flag-bl {
  background-image: url('#{$assets-path}/images/countries/saint-barthelemy.svg');
}

.saint-helena,
.flag-sh {
  background-image: url('#{$assets-path}/images/countries/saint-helena-ascension-and-tristan-da-cunha.svg');
}

.saint-kitts-and-nevis,
.flag-kn {
  background-image: url('#{$assets-path}/images/countries/saint-kitts-and-nevis.svg');
}

.saint-lucia,
.flag-lc {
  background-image: url('#{$assets-path}/images/countries/saint-lucia.svg');
}

.saint-martin-dutch-part {
  background-image: url('#{$assets-path}/images/countries/saint-martin-dutch-part.svg');
}

.saint-martin-french-part {
  background-image: url('#{$assets-path}/images/countries/saint-martin-french-part.svg');
}

.saint-pierre-and-miquelon,
.flag-pm,
.flag-mf {
  background-image: url('#{$assets-path}/images/countries/saint-pierre-and-miquelon.svg');
}

.saint-vincent-and-the-grenadines,
.flag-vc {
  background-image: url('#{$assets-path}/images/countries/saint-vincent-and-the-grenadines.svg');
}

.samoa,
.flag-ws {
  background-image: url('#{$assets-path}/images/countries/samoa.svg');
}

.san-marino,
.flag-sm {
  background-image: url('#{$assets-path}/images/countries/san-marino.svg');
}

.sao-tome-and-principe,
.flag-st {
  background-image: url('#{$assets-path}/images/countries/sao-tome-and-principe.svg');
}

.saudi-arabia,
.flag-sa {
  background-image: url('#{$assets-path}/images/countries/saudi-arabia.svg');
}

.scotland {
  background-image: url('#{$assets-path}/images/countries/scotland.svg');
}

.senegal,
.flag-sn {
  background-image: url('#{$assets-path}/images/countries/senegal.svg');
}

.serbia,
.flag-rs {
  background-image: url('#{$assets-path}/images/countries/serbia.svg');
}

.seychelles,
.flag-sc {
  background-image: url('#{$assets-path}/images/countries/seychelles.svg');
}

.sierra-leone,
.flag-sl {
  background-image: url('#{$assets-path}/images/countries/sierra-leone.svg');
}

.singapore,
.flag-sg {
  background-image: url('#{$assets-path}/images/countries/singapore.svg');
}

.sint-maarten,
.flag-sx {
  background-image: url('#{$assets-path}/images/countries/sint-maarten-dutch-part.svg');
}

.slovakia,
.flag-sk {
  background-image: url('#{$assets-path}/images/countries/slovakia.svg');
}

.slovenia,
.flag-si {
  background-image: url('#{$assets-path}/images/countries/slovenia.svg');
}

.solomon-islands,
.flag-sb {
  background-image: url('#{$assets-path}/images/countries/solomon-islands.svg');
}

.somalia,
.flag-so {
  background-image: url('#{$assets-path}/images/countries/somalia.svg');
}

.south-africa,
.flag-za {
  background-image: url('#{$assets-path}/images/countries/south-africa.svg');
}

.south-america {
  background-image: url('#{$assets-path}/images/countries/south-america.svg');
}

.south-sudan,
.flag-ss {
  background-image: url('#{$assets-path}/images/countries/south-sudan.svg');
}

.spain,
.spanish,
.sb-icon.es,
.flag-es {
  background-image: url('#{$assets-path}/images/countries/spain.svg');
}

.sri-lanka,
.flag-lk {
  background-image: url('#{$assets-path}/images/countries/sri-lanka.svg');
}

.state-union-of-serbia-and-montenegro {
  background-image: url('#{$assets-path}/images/countries/state-union-of-serbia-and-montenegro.svg');
}

.sudan,
.flag-sd {
  background-image: url('#{$assets-path}/images/countries/sudan.svg');
}

.suriname,
.flag-sr {
  background-image: url('#{$assets-path}/images/countries/suriname.svg');
}

.svalbard-and-jan-mayen,
.flag-sj {
  background-image: url('#{$assets-path}/images/countries/svalbard-and-jan-mayen.svg');
}

.swaziland,
.flag-sz {
  background-image: url('#{$assets-path}/images/countries/swaziland.svg');
}

.sweden,
.flag-se {
  background-image: url('#{$assets-path}/images/countries/sweden.svg');
}

.switzerland,
.flag-ch {
  background-image: url('#{$assets-path}/images/countries/switzerland.svg');
}

.syria,
.flag-sy {
  background-image: url('#{$assets-path}/images/countries/syria.svg');
}

.taiwan,
.flag-tw {
  background-image: url('#{$assets-path}/images/countries/taiwan.svg');
}

.tajikistan,
.flag-tj {
  background-image: url('#{$assets-path}/images/countries/tajikistan.svg');
}

.thailand,
.flag-th {
  background-image: url('#{$assets-path}/images/countries/thailand.svg');
}

.the-democratic-republic-of-the-congo {
  background-image: url('#{$assets-path}/images/countries/the-democratic-republic-of-the-congo.svg');
}

.timor-leste,
.flag-tl {
  background-image: url('#{$assets-path}/images/countries/timor-leste.svg');
}

.togo,
.flag-tg {
  background-image: url('#{$assets-path}/images/countries/togo.svg');
}

.tokelau,
.flag-tk {
  background-image: url('#{$assets-path}/images/countries/tokelau.svg');
}

.tonga,
.flag-to {
  background-image: url('#{$assets-path}/images/countries/tonga.svg');
}

.trinidad-and-tobago,
.flag-tt {
  background-image: url('#{$assets-path}/images/countries/trinidad-and-tobago.svg');
}

.tunisia,
.flag-tn {
  background-image: url('#{$assets-path}/images/countries/tunisia.svg');
}

.turkey,
.flag-tr {
  background-image: url('#{$assets-path}/images/countries/turkey.svg');
}

.turkmenistan,
.flag-tm {
  background-image: url('#{$assets-path}/images/countries/turkmenistan.svg');
}

.turks-and-caicos-islands,
.flag-tc {
  background-image: url('#{$assets-path}/images/countries/turks-and-caicos-islands.svg');
}

.tuvalu,
.flag-tv {
  background-image: url('#{$assets-path}/images/countries/tuvalu.svg');
}

.uganda,
.flag-ug {
  background-image: url('#{$assets-path}/images/countries/uganda.svg');
}

.ukraine,
.flag-uk,
.flag-ua {
  background-image: url('#{$assets-path}/images/countries/ukraine.svg');
}

.united-arab-emirates,
.flag-ae {
  background-image: url('#{$assets-path}/images/countries/united-arab-emirates.svg');
}

.united-kingdom,
.great-britain-and-northern-ireland,
.flag-gb,
.flag-en {
  background-image: url('#{$assets-path}/images/countries/united-kingdom.svg');
}

.united-republic-of-tanzania,
.flag-tz {
  background-image: url('#{$assets-path}/images/countries/united-republic-of-tanzania.svg');
}

.united-states-minor-outlying-islands,
.flag-um {
  background-image: url('#{$assets-path}/images/countries/united-states-minor-outlying-islands.svg');
}

.uruguay,
.flag-uy {
  background-image: url('#{$assets-path}/images/countries/uruguay.svg');
}

.usa,
.united-states-of-america,
.flag-us {
  background-image: url('#{$assets-path}/images/countries/usa.svg');
}

.uzbekistan,
.flag-uz {
  background-image: url('#{$assets-path}/images/countries/uzbekistan.svg');
}

.vanuatu,
.flag-vu {
  background-image: url('#{$assets-path}/images/countries/vanuatu.svg');
}

.venezuela,
.flag-ve {
  background-image: url('#{$assets-path}/images/countries/venezuela.svg');
}

.vietnam,
.flag-vn {
  background-image: url('#{$assets-path}/images/countries/vietnam.svg');
}

.virgin-islands-british,
.flag-vg {
  background-image: url('#{$assets-path}/images/countries/virgin-islands-british.svg');
}

.virgin-islands-u-s,
.flag-vi {
  background-image: url('#{$assets-path}/images/countries/virgin-islands-u-s.svg');
}

.wales {
  background-image: url('#{$assets-path}/images/countries/wales.svg');
}

.wallis-and-futuna,
.flag-wf {
  background-image: url('#{$assets-path}/images/countries/wallis-and-futuna.svg');
}

.western-sahara,
.flag-eh {
  background-image: url('#{$assets-path}/images/countries/western-sahara.svg');
}

.west-germany {
  background-image: url('#{$assets-path}/images/countries/west-germany.svg');
}

.world,
.santa-rita,
.srl-matches {
  background-image: url('#{$assets-path}/images/countries/world.svg');
}

.yemen,
.flag-ye {
  background-image: url('#{$assets-path}/images/countries/yemen.svg');
}

.zaire {
  background-image: url('#{$assets-path}/images/countries/zaire.svg');
}

.zambia,
.flag-zm {
  background-image: url('#{$assets-path}/images/countries/zambia.svg');
}

.zimbabwe,
.flag-zw {
  background-image: url('#{$assets-path}/images/countries/zimbabwe.svg');
}
