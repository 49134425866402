@import 'sportsbook/mobile-web/shared/layout/general';
@import 'sportsbook/mobile-web/shared/layout/slider';

.slider-main {
  --slider-m: 10px -10px;
}

.tab-bar {
  --tab-line: 1px solid var(--grey-primary-dark-1);
  --tab-line-bg: var(--grey-primary-dark-1);
}

.navbar-slider__entry {
  --promo-btn-bg: var(--neutral-dark-1);
  --promo-btn-bg-main: var(--neutral-dark-1);
  --promo-btn-color-main: var(--white);
  --promo-btn-radius: var(--radius-2);

  border: var(--border-3);
}
