body {
  &.modal-open {
    overflow: hidden;
    z-index: 20;
    -webkit-overflow-scrolling: auto;
  
    & ~ iframe{
      z-index: 19 !important;
    } 
  }

  &.modal-login {
    width: 100%;
    height: 100%;
    min-height: 100%;
  }
}



:root {
  //temporary nested
  .modal-open {
    //brand color
    --brand-color: #fbcb00;

    //inputs & labels & select colors
    --c-label: white;
    --c-input: #a3a3a3;
    --c-input-place: #a3a3a3;
    --c-input-line: #444;
    --input-gy: 0;
    --input-gx: 15px;
    --c-select: #a3a3a3;
    --c-select-line: #444;
    --select-gy: 0;
    --select-gx: 15px;

    //general fonts
    --c-1: #b1b1b1;

    //state fonts
    --c-error-1: #d70000;

    //headline fonts
    --headline-succes: #00b372;
    --headline-error: #f10;

    //buttons colors
    --btn-primary: #313137;
    --c-btn-primary: #fff;
    --btn-active: #fbcb00;
    --c-btn-active: #000;
    --btn-succes: #00b372;
    --c-btn-success: var(--white);
    --btn-error: #f10;
    --c-btn-error: var(--white);

    //border
    --line-1: #313137;
    --line-2: #313137;
  }
}

.sb-modal {
  --basic-color: #fbcb00;

  pointer-events: none;
  opacity: 0;
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-100%);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100%;
  padding: 10px;
  @include mobile-side-gutter(10px);

  background-color: var(--body);
  transition: opacity 0.2s ease;

  @include breakpoint-min(1200px) {
    max-width: 1200px;
    left: calc(50% - 600px);
  }

  &.is-visible {
    pointer-events: initial;
    opacity: 1;
    transform: translateY(0);

    &.sb-modal--taxes {
      opacity: 0;
    }
  }

  .is-highlighted {
    color: var(--brand-color);
  }

  .btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    line-height: 1.1;

    &--bg-primary {
      color: var(--c-btn-primary);

      &.is-active {
        color: var(--black);
        background-color: var(--basic-color);
      }
    }

    &--success {
      color: var(--c-btn-success);
      background-color: var(--btn-succes);
    }

    &--error {
      color: var(--c-btn-error);
      background-color: var(--btn-error);
    }

    &--big {
      height: 47px;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: -0.05em;
      text-transform: uppercase;
      border-radius: var(--radius-primary);

      @include breakpoint-max(359px) {
        font-size: 12px;
      }
    }
  }

  &__content {
    position: relative;
    overflow: hidden;
    margin-bottom: 23px;
  }

  &__headline {
    margin-bottom: 20px;
    color: var(--white);
    font-size: 18px;
    font-weight: 800;
    letter-spacing: -0.025em;
    text-align: center;
    text-transform: uppercase;

    &--secondary {
      font-size: 14px;
      letter-spacing: -0.05em;
      text-align: left;
    }

    &--success {
      color: var(--headline-succes);
    }

    &--error {
      color: var(--headline-error);
    }
  }

  &__text {
    color: var(--c-1);
    font-size: 14px;
    letter-spacing: -0.025em;

    &--big {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.025em;
      text-transform: uppercase;
    }
  }

  &__markets {
    overflow-y: scroll;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    height: 100%;
    max-height: 100%;
    padding: 10px 0 0;

    .btn {
      width: 100%;
      height: 47px;
      margin-bottom: 10px;
      font-weight: 700;
    }
  }

  &__action {
    position: relative;
    flex-shrink: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    column-gap: 20px;
    margin-top: auto;
    margin-bottom: 22px;
  }

  &__video-box {
    height: 300px;
    margin-bottom: 20px;
    border-radius: 3px;
  }

  .lv-modal-general-error {
    color: var(--c-error-1);
  }
}

.sb-modal .sb-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  &__title {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: var(--white);
    font-size: 18px;
    font-style: italic;
    font-weight: 800;
    letter-spacing: -0.05em;
    line-height: 1;
    text-transform: uppercase;
  }

  .btn {
    width: 42px;
    height: 42px;
    margin-left: 8px;
    background: var(--primary-color);
    color: var(--white);
    font-size: 20px;
    font-weight: 800;

    .icon-cp svg {
      width: 14px;
      height: 14px;
      fill: white;
    }
  }
}

.sb-modal .sb-checkbox {
  margin-bottom: 15px;

  .sb-error-modal {
    height: 28px;
    margin-left: 8px;
    font-weight: 700;
  }

  &__state {
    flex-shrink: 0;
    width: 47px;
    height: 47px;
    margin-right: 15px;
    border-color: var(--line-grey-2);

    &::after {
      width: 100%;
      height: 100%;
      background: transparent url('/img/new-version/icons/basic/tick.svg') no-repeat center/22px;
    }
  }

  &__title {
    color: var(--brand-color);
    font-size: 14px;
    font-weight: 800;
    letter-spacing: -0.05em;
  }
}

.sb-form {
  &__title {
    display: inline-block;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  &__input-box,
  &__select-box {
    position: relative;
    display: flex;
    flex-flow: column;

    &--user {
      margin-bottom: 18px;
    }
  }

  &__label {
    margin-bottom: 10px;
    color: var(--brand-color);
    font-size: 14px;
    font-weight: 800;
    font-style: italic;
    line-height: 1;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  &__input {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--input-gy) var(--input-gx);
    height: 45px;
    background-color: var(--body);
    border: 2px solid var(--c-input-line);
    border-radius: var(--radius-primary);
    outline: none;

    &-title {
      color: var(--c-1);
      font-size: 14px;
      letter-spacing: -0.35px;
    }
  }

  &__input,
  &__input::placeholder {
    color: var(--c-input);
    caret-color: var(--c-input);
  }

  &__unit {
    position: absolute;
    bottom: 0;
    right: 15px;
    height: 45px;
    display: flex;
    align-items: center;
    color: var(--brand-color);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  &__buttons {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    column-gap: 20px;
    margin: 34px 0 30px;
  }
}

.sb-form-error {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--c-error-1);
  font-size: 12px;
  letter-spacing: -0.025em;
  line-height: 1;
}

.sb-form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--select-gy) var(--select-gy);
  height: 45px;
  color: var(--c-select);
  caret-color: var(--c-select);
  background-color: var(--body);
  border: 2px solid var(--c-select-line);
  border-radius: var(--radius-primary);
  outline: none;
  appearance: none;
}

.sb-modal--market-selection {
  .sb-heading {
    margin-bottom: 5px;
  }

  .sb-modal__content {
    margin-bottom: 0;

    &::before,
    &::after {
      pointer-events: none;
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      height: 10px;
      width: 100%;
      background: linear-gradient(to bottom, var(--body) 20%, rgb(0 0 0 / 0%));
      transition: opacity 0.3s ease;
      content: '';
    }

    &::after {
      top: auto;
      bottom: 0;
      background: linear-gradient(to top, var(--body) 20%, rgb(0 0 0 / 0%));
    }
  }
}

.sb-modal--abroad {
  z-index: 99999;

  &.is-active,
  &.active,
  &.is-visible {
    pointer-events: initial;
    transform: translateY(0);
    opacity: 1;
  }

  .logo {
    height: 41px;
  }

  .btn {
    padding: 0 8px;
    height: 47px;
    width: 100%;
    color: var(--black);
    background-color: var(--brand-color);
    font-size: 13px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.075em;

    &:first-child {
      color: var(--white);
      background-color: #282728;
    }
  }

  .sb-choice-separator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    background-color: #100e11;
    color: var(--c-1);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.65px;
    border-radius: 50%;
  }

  .sb-modal__headline {
    position: relative;

    &::after {
      z-index: -1;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: none;
      width: 440px;
      height: 60%;
      box-shadow: inset 0 25px 30px 20px #100e11;
      background-image: url('/img/new-version/background/map.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      content: '';

      @include breakpoint-min(360px) {
        display: block;
      }

      @include breakpoint-height-max(600px) {
        display: none;
      }
    }
  }

  .sb-modal__text {
    margin-bottom: 50px;
    text-align: center;

    i {
      color: var(--brand-color);
      display: block;
      margin: 20px 0;
    }
  }

  .sb-modal__header {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }

  .sb-modal__content {
    overflow: initial;
    margin-bottom: 0;

    &::after,
    &::before {
      opacity: 0;
      pointer-events: none;
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 100%;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #100e11), to(rgb(16 14 17 / 0%)));
      background: linear-gradient(to bottom, #100e11 20%, rgb(16 14 17 / 0%));
      transition: opacity 0.3s ease;
      content: '';
    }
  }

  &.sb-modal--abroad-de,
  &.abroad-de {
    // COUNTRY BLOCKAGE MODAL FOR GERMANY
    .btn:first-child {
      color: var(--black);
      background-color: var(--brand-color);
    }

    .sb-modal__headline::after {
      background-image: url('/img/new-version/background/map_de.png');
    }
  }
}

.sb-modal {
  .sb-icon-arrow {
    width: 15px;
    height: 15px;
  }

  .mat-checkbox {
    display: none;
  }

  .language-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .sb-select {
    height: 45px;
    padding: 0 15px;
    background-color: #110f11;
    color: #919191;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: calc(100% - 15px) 50%;
    background-image: url('/img/new-version/icons/basic/arrow-down.svg');
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.05em;
    appearance: none;
    border: 1px solid var(--line-grey-2);
    border-radius: 3px;

    option {
      color: #919191;
    }
  }

  .sb-shadow-wrapp {
    position: relative;
    overflow: hidden;
    margin-bottom: 22px;

    &::before,
    &::after {
      content: '';
      opacity: 0;
      pointer-events: none;
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 100%;
      background: linear-gradient(to bottom, #110f11 20%, rgba(#110f11, 0));
      transition: opacity 0.3s ease;
    }

    &::after {
      top: auto;
      bottom: 0;
      background: linear-gradient(to top, #110f11 20%, rgba(#110f11, 0));
    }

    &.shadow-bottom::after,
    &.shadow-top::before {
      opacity: 1;
    }
  }

  .sb-choice-separator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    background-color: #110f11;
    color: var(--c-1);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.05em;
    border-radius: 50%;
  }

  .sb-keyboard {
    margin: 32px 0;

    &__row {
      display: flex;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__key {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc((100% - 32px) / 3);
      height: 47px;
      margin-right: 16px;
      background-color: var(--primary-color);
      border-radius: var(--radius-primary);

      &:last-child {
        margin-right: 0;
      }

      &:only-child {
        margin: 0 auto;
      }
    }
  }

  .sb-error-modal,
  .sb-error-input {
    color: var(--c-error-1);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.025em;
  }

  .sb-error-input {
    position: absolute;
    top: 0;
    right: 0;
    max-width: calc(50%);
    line-height: 1;
  }

  &__text {
    a {
      color: var(--brand-color);
      font-weight: 700;
      letter-spacing: -0.05em;
      text-transform: uppercase;
    }
  }

  &__scroll-content {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }

  &__options {
    display: flex;
  }

  &__buttons {
    position: relative;
    display: flex;
    justify-content: center;

    &--choice {
      .btn {
        flex: 1 1 50%;
        padding: 0 12px;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  &__footer {
    display: flex;
    margin-bottom: 20px;
  }

  &__expand {
    position: relative;
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 800;
    font-style: italic;
    letter-spacing: -0.7px;
    text-transform: uppercase;

    &::before {
      z-index: 10;
      pointer-events: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-100%);
      height: 50px;
      width: 100%;
      background: linear-gradient(0deg, #110f11 20%, rgb(16 14 17 / 0%));
    }

    &.is-active {
      margin-top: 15px;

      &::before {
        opacity: 0;
      }
    }
  }

  &__skip-action {
    flex: 1 1 auto;
    padding: 0 5px;
    color: #636363;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.05em;
    text-align: center;
    text-transform: uppercase;
  }

  &__user {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    color: var(--c-1);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.65px;
    line-height: 1;

    &-title {
      display: inline-block;
      margin-right: 2px;
    }

    .sb-icon-user,
    .icon-cp.icon-user {
      height: 15px;
      width: 15px;
      margin-right: 5px;
      background-size: contain;
    }

    .icon-cp.icon-user {
      fill: white;
    }
  }

  &__video-box {
    height: 300px;
    margin-bottom: 20px;
    border-radius: 3px;

    video {
      display: block;
      width: 100%;
      height: 100%;
    }

    &.is-hidden {
      display: none;
      margin: 0;
    }
  }

  &__share-link {
    display: block;
    color: var(--brand-color);
    font-size: 18px;
    font-style: italic;
    font-weight: 800;
    line-height: 1;
    letter-spacing: -0.05em;
    word-break: break-all;
  }
}

.sb-pin-code,
.pin-code {
  &__panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__selection {
    display: flex;
  }

  &__remove {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid var(--white);
    border-left: 0;
    border-radius: 3px;

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      width: 14px;
      height: 14px;
      transform: translateX(-6px) rotate(-45deg);
      border-left: 1px solid var(--white);
      border-top: 1px solid var(--white);
      border-radius: 3px;
    }

    .sb-icon {
      position: absolute;
      top: 50%;
      left: calc(50% - 1px);
      width: 10px;
      height: 10px;
      transform: translate(-50%, -50%);
    }
  }

  .pin-code__remove {
    background-image: url(/img/new-version/icons/white/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px 10px;
  }

  &__number {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    font-size: 14px;
    line-height: 1;
    letter-spacing: -0.7px;
    border: 1px solid var(--white);
    border-radius: 50%;

    &::after {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--white);
      border-radius: 50%;
      content: '';
    }

    &:last-child {
      margin-right: 0;
    }

    &.is-cover::after {
      opacity: 1;
    }
  }
}

.sb-modal--framed {
  .sb-modal__content {
    overflow: initial;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  .sb-modal__action {
    margin-bottom: 0;
  }
}

.sb-modal--funds-protection,
.sb-modal--income-source,
.sb-modal--stay-in-touch,
.sb-modal--responsible-game,
.sb-modal--funds-protection {
  .sb-modal__content {
    height: 100%;
  }
}

/* === MODAL LOGIN - DONE  === */
.sb-modal--login {
  .sb-modal__content {
    z-index: 2;
    overflow-y: scroll;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: end;
  }

  bcf-modal-login {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .sb-error-modal {
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    padding: 18px 30px;
    margin-top: 8px;
    flex-shrink: 0;
    background-color: #a90101;
    color: var(--white);
    font-weight: 600;
    text-align: center;
    border-radius: 8px;

    &:first-child {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: auto;
    }
  }

  .sb-icon-logo {
    flex-shrink: 0;
    width: 180px;
    height: 45px;
    margin-top: auto;
    margin-bottom: 70px;
    background-position-y: 100%;
  }

  .sb-icon-eye {
    position: absolute;
    bottom: 12px;
    right: 15px;
    width: 20px;
    height: 20px;
  }

  .sb-modal__headline {
    margin-top: auto;
  }

  .sb-modal__user + .sb-modal__headline {
    margin-top: 0;
  }

  .sb-form {
    position: relative;
    margin-top: 0;

    &__input-box {
      z-index: 1;

      &--password {
        .sb-form__input {
          padding-right: 40px;
        }

        .icon-cp {
          position: absolute;
          bottom: 12px;
          right: 15px;
          width: 20px;
          height: 20px;
          fill: var(--grey);
        }
      }
    }
  }

  .sb-modal__header {
    z-index: 2;
    position: relative;
  }

  .sb-modal__footer {
    justify-content: space-between;
    margin: 0;

    &:nth-of-type(3) {
      margin-top: 8px;
    }
  }

  .sb-modal__skip-action {
    flex: 0 1 calc((100% - 20px) / 2);

    &:only-child {
      flex: 1 1 100%;
    }
  }
}

/* === MODAL FACILITIES === */
.sb-modal--facilities {
  .sb-modal__options {
    margin: 30px 0;

    .sb-icon {
      flex: 1 1 auto;
      height: 110px;
      background-size: contain;
    }
  }

  .sb-modal__text {
    text-align: center;
  }

  .sb-modal__buttons {
    position: relative;
    margin-bottom: 30px;

    .btn {
      flex: 1 1 50%;
      padding: 0 12px;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

/* === MODAL FINGERPRINT === */
.sb-modal--fingerprint {
  .sb-modal__content {
    justify-content: flex-end;
  }

  .sb-modal__footer {
    margin-top: 20px;
  }

  .sb-modal__fingerprint {
    margin: 25px 0;
  }

  .sb-modal__buttons {
    .btn {
      flex: 1 1 100%;
      text-align: center;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .sb-icon {
    display: block;
    width: 110px;
    height: 110px;
    margin: 0 auto;
    background-size: contain;
  }

  .sb-modal__skip-action {
    @include breakpoint-min(399px) {
      padding: 0 20px;
    }
  }
}

.sb-modal--face-id,
.sb-modal--fingerprint,
.sb-modal--login-alternative {
  .sb-modal__content {
    justify-content: flex-end;
  }

  .sb-modal__footer {
    margin-top: 20px;
  }

  .sb-modal__fingerprint {
    margin: 25px 0;
  }

  .sb-modal__buttons {
    .btn {
      flex: 1 1 100%;
      text-align: center;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .sb-icon {
    display: block;
    width: 110px;
    height: 110px;
    margin: 0 auto;
    background-size: contain;
  }

  .sb-modal__skip-action {
    @include breakpoint-min(399px) {
      padding: 0 20px;
    }
  }
}

/* === MODAL REMIND PASSWORD === */
.sb-modal--remind-password {
  .btn--confirm {
    margin-top: 32px;
  }
}

/* === MODAL SCAN QR === */
.sb-modal--generate-qr,
.sb-modal--scan-qr {
  .sb-modal__content {
    overflow-y: scroll;
    margin-bottom: 0;
  }

  .sb-error-modal {
    position: absolute;
    top: 0;
    right: 0;
  }

  .sb-form {
    margin-top: 0;

    &__input-box {
      margin-bottom: 18px;
    }
  }
}

/* === MODAL PAYMENTICON === */
.sb-modal.modal-paymenticon {
  .sb-modal__content {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(55px + env(safe-area-inset-bottom));
  }
}

/* === MODAL SCAN QR === */
.sb-modal--scan-many {
  .sb-modal__content {
    margin-bottom: 0;
    padding-bottom: 10px;

    &::after {
      pointer-events: none;
      z-index: 10;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 20px;
      width: 100%;
      background: linear-gradient(to top, #110f11 20%, rgba(#110f11, 0));
      content: '';
    }
  }

  .sb-form {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;

    .btn {
      flex: auto;
      margin-top: 12px;
    }

    &__label {
      margin-bottom: 10px;
    }
  }

  .qrcode-scanner-multi-cp {
    display: block;
    overflow: scroll;
    max-height: 100%;
    padding-bottom: 10px;
  }

  .scan-multi-coupons {
    display: flex;
    flex-flow: column;
    min-height: 285px;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__list {
      margin: 12px 0 auto;
    }

    &__name {
      padding-right: 3px;
      font-size: 16px;
      font-style: italic;
      font-weight: 800;
      letter-spacing: -0.05em;
      text-transform: uppercase;

      span {
        margin-left: 3px;
        color: var(--brand-color);
      }
    }

    &__entry {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      text-transform: uppercase;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    &__empty {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      margin: auto 0;

      .sb-icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        margin-bottom: 12px;
      }
    }

    &__data {
      display: flex;
      flex-flow: column;
      letter-spacing: -0.025em;
      line-height: 1;
    }

    &__status {
      display: flex;
      align-items: center;
      padding-right: 3px;
      font-size: 15px;
      font-weight: 800;
      letter-spacing: -0.05em;

      .sb-icon {
        display: inline-block;
        height: 25px;
        width: 35px;
        flex-shrink: 0;
        background-color: var(--primary-color);
        background-size: 10px;
        border-radius: 10px;
        margin-left: 8px;
      }
    }

    &__id {
      font-weight: 700;
    }

    &__value {
      color: var(--brand-color);
      font-weight: 600;
    }
  }

  .scan-multi-summary {
    display: flex;
    flex-wrap: wrap;
    margin: auto 0 12px;

    &__data {
      width: 50%;
      margin-bottom: 2px;
      font-size: 12px;
      font-style: italic;
      font-weight: 700;
      letter-spacing: -0.05em;
      text-transform: uppercase;

      span {
        display: inline-block;
        margin-left: 3px;
        color: var(--c-1);
      }

      &--amount,
      &--amount span {
        color: var(--brand-color);
      }
    }
  }
}

/* === MODAL INOBX  === */
.sb-modal--inbox {
  .sb-modal__content {
    overflow-y: scroll;
    margin-bottom: 10px;
  }

  .sb-modal__headline {
    text-align: initial;
  }

  .sb-modal__text {
    margin-bottom: 12px;
  }

  .sb-modal__action {
    position: sticky;
    margin-bottom: 0;
    background-color: var(--body);
    bottom: 0;
    box-shadow: 0 -2px 13px 5px var(--black);

    .btn {
      border: initial;
    }
  }
}

/* === MODAL ACCEPT REGULATIONS ( GDPR ) === */
.sb-modal--accept-regulation,
.sb-modal--confirm-regulation {
  overflow: hidden;
  justify-content: flex-start;

  .sb-heading__title {
    margin-right: 0;
  }

  .sb-modal__header {
    position: relative;

    &::after {
      pointer-events: none;
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      width: 100%;
      height: 25px;
      background: linear-gradient(to bottom, #110f11 20%, rgba(#110f11, 0));
      content: '';
    }
  }

  .sb-modal__content {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    padding-top: 25px;
  }

  .sb-modal__core {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  .sb-modal__text {
    margin-bottom: 20px;
    text-align: left;
  }

  .sb-expand {
    margin-bottom: 30px;
  }

  .sb-modal__action {
    z-index: 11;
    position: sticky;
    bottom: 0;
    background-color: #110f11;
    box-shadow: 0 -2px 13px 5px var(--black);
  }
}

/* === MODAL ACCEPT REGULATIONS ( GDPR )  === */
.sb-modal--accept-regulation {
  .sb-heading {
    margin-bottom: 10px;
  }

  .sb-shadow-wrapp {
    margin-bottom: 12px;

    &::before,
    &::after {
      opacity: 1;
      height: 20px;
    }
  }

  .sb-modal__scroll-content {
    padding: 10px 0 15px;
  }
}

/* ===  MODAL CONFIRM REGULATIONS  === */
.sb-modal--confirm-regulation {
  .sb-modal__core {
    overflow: auto;
    height: 100%;
  }
}

/* === MODAL INCOME SOURCE  === */
.sb-modal--income-source {
  form {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  .sb-modal__action {
    margin-top: auto;
  }

  .sb-form__select-box {
    margin: 22px 0;
  }
}

/* === MODAL RESPONSIBL GAMBLING LIMITS  === */
.sb-modal--responsible-game {
  .sb-heading {
    margin-bottom: 5px;
  }

  .sb-modal__headline {
    margin-bottom: 10px;
    text-align: initial;
  }

  .sb-expand {
    margin: 17px 0 32px;
  }

  .sb-form {
    margin-top: 0;
    padding-bottom: 10px;

    &__input-box {
      margin-bottom: 15px;
    }

    &__box--stake {
      padding-bottom: 10px;
    }

    &__box--session {
      margin-bottom: 58px;
    }
  }

  .sb-modal__content,
  .sb-shadow-wrapp {
    margin-bottom: 0;
  }

  .sb-shadow-wrapp {
    &::before,
    &::after {
      opacity: 1;
    }
  }

  .sb-modal__content {
    overflow: hidden;
  }

  .sb-modal__scroll-content {
    padding: 25px 0 15px;
  }

  .sb-modal__core {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }
}

/* === MODAL BET - CONFIRMATION  === */
.sb-modal--bet-confirmation {
  background-color: rgb(16 14 17 / 98%);

  &.is-visible {
    animation-delay: 0.4s;
  }

  .sb-modal__header {
    display: none;
  }

  .sb-modal__content {
    overflow-y: scroll;
    align-self: center;
    width: 100%;
    margin-bottom: 0;
  }

  .sb-modal__action {
    position: relative;
    bottom: 0;
    flex-shrink: 0;
    flex-flow: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: 22px;
    display: flex;

    .btn {
      flex: 0 1 47px;
      width: 200px;

      &:first-child {
        margin-right: 0;
        color: #646464;
      }

      &--bg-transparent {
        border: initial;
      }

      &--bg-primary {
        margin-top: 15px;
      }
    }
  }

  .sb-loader {
    margin: auto;
  }

  .bet-confirmation {
    display: flex;
    flex-flow: column;
    flex-grow: 1;

    &__status {
      position: relative;
      padding-bottom: 30px;

      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 50px);
        height: 2px;
        background-color: var(--primary-color);
        content: '';
      }

      .sb-icon,
      .icon-cp {
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        width: 58px;
        height: 58px;
        background-color: var(--brand-color);
        border-radius: 50%;
        box-shadow: 0 0 12px 8px rgb(16 14 17 / 92%);
      }

      .sb-icon {
        display: inline-block;
        background-image: url('/img/new-version/icons/black/tick.svg');
        background-size: 28px;
      }

      .icon-cp {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 28px;
          height: 28px;
        }
      }

      .btn-share {
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        height: 35px;
        background-size: 18px;

        span {
          position: absolute;
          top: 50%;
          left: -12px;
          transform: translate(-100%, -50%);
          color: #a3a3a3;
          font-style: italic;
        }

        .icon-cp {
          display: block;
          width: 18x;
          height: 18px;
          position: absolute;
          top: 0;
          background: none;
          box-shadow: none;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &__headline {
      max-width: 320px;
      margin: 40px auto 20px;
      font-size: 35px;
      font-weight: 800;
      font-style: italic;
      letter-spacing: -0.05em;
      text-align: center;
      text-transform: uppercase;

      @include breakpoint-min(360px) {
        margin: 50px auto 45px;
        font-size: 40px;
      }
    }

    &__details {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      flex-shrink: 0;
      padding: 45px 0;

      @include breakpoint-min(360px) {
        padding: 60px 0;
      }
    }

    &__info {
      margin-bottom: 2px;
      color: #bb9700;
      font-size: 18px;
      font-weight: 600;
      font-style: italic;
      letter-spacing: -0.025em;
      text-transform: uppercase;
      text-align: center;
    }

    &__amount {
      color: var(--brand-color);
      font-size: 42px;
      font-weight: 800;
      font-style: italic;
      letter-spacing: -0.025em;
      text-transform: uppercase;
      word-break: break-word;

      @include breakpoint-min(360px) {
        font-size: 48px;
      }
    }

    &__summary {
      overflow: hidden;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 16px;
      color: #a3a3a3;
      font-size: 18px;
      font-style: italic;
      font-weight: 600;
      letter-spacing: -0.05em;
      text-transform: uppercase;

      &.has-extra-info {
        flex-wrap: wrap;
      }

      span {
        overflow: hidden;
        display: block;
        width: 100%;
        padding-right: 3px;
        padding-left: 5px;
        color: var(--white);
        font-weight: 800;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__data {
      overflow: hidden;
      display: flex;
      padding-right: 2px;

      &:first-child {
        margin-right: 20px;
      }

      &.is-spread {
        width: 100%;
        justify-content: center;
        margin-top: 10px;

        span {
          width: auto;
        }
      }
    }
  }
}

/* === MODAL BET - CONFIRMATION PROCESSING  === */
processing-modal .sb-modal--bet-confirmation {
  .bet-confirmation__headline {
    margin-top: 110px;
  }

  .sb-loader div {
    width: 15px;
    height: 15px;
  }
}

/* === MODAL FUNDS PROTECTION === */
.sb-modal--funds-protection {
  form {
    overflow: hidden;
    display: flex;
    flex-flow: column;
  }

  .sb-modal__scroll-content {
    margin-bottom: 20px;
  }

  .sb-modal__text a {
    font-weight: 700;
  }

  .sb-form {
    margin-top: 23px;
    margin-bottom: 25px;

    &__input-box {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/* === MODAL ALERT  === */
.sb-modal--alert {
  .sb-modal__header {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }

  .sb-heading .sb-icon-logo {
    width: 177px;
    height: 41px;
  }

  .sb-modal__text {
    margin-bottom: 50px;
    text-align: center;
  }

  .sb-modal__headline {
    text-align: center;
  }
}

/* === MODAL MARKET SELECTION  === */
.sb-modal--market-selection {
  .btn:only-child {
    width: 100%;
  }

  .sb-modal__content {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    height: 100%;
  }

  .sb-modal__headline {
    margin-bottom: 5px;
  }

  .sb-breadcrumb {
    display: flex;
    align-items: center;
    justify-content: center;

    .sb-icon {
      width: 21px;
      height: 21px;
    }

    &__list {
      display: flex;
      justify-content: center;
      margin-top: 13px;

      &:empty {
        margin-top: 0;
      }
    }

    &__item {
      z-index: 1;
      position: relative;
      display: flex;
      flex-flow: column;
      align-items: center;
      color: #828282;
      font-size: 10px;
      letter-spacing: -0.5px;
      text-transform: uppercase;
      padding: 4px 13px 4px 6px;
      outline: none;

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: block;
        color: #fff;
        font-size: 16px;
        content: '/';
      }

      &:last-child::after {
        display: none;
      }
    }

    &__name {
      margin-top: 3px;
      white-space: nowrap;
    }
  }

  .sb-shadow-wrapp {
    margin-bottom: 0;
  }
}

/* === MODAL MERCHANT PLACE  === */
.sb-modal--merchant-place {
  .sb-modal {
    &__header {
      .btn.btn-close {
        background: var(--primary-color);
        border-color: transparent;
        width: 47px;
        height: 33px;

        svg {
          width: 14px;
          height: 14px;

          polygon {
            fill: white;
          }
        }

        &::after {
          display: none;
        }
      }
    }

    &__headline {
      display: flex;
      flex-flow: column;

      span {
        display: block;
        margin-top: 15px;
        color: var(--brand-color);

        &.sb-modal__stake {
          color: var(--basic-color);
        }
      }
    }
  }

  &.sb-modal--confirm-bet {
    .sb-heading {
      background-color: var(--body);
    }

    .sb-modal__content {
      margin-top: auto;
    }
  }
}

/* === MODAL MERCHANT CONFIRMATION  === */
.sb-modal--merchant-confirmation {
  .sb-form {
    &__label {
      text-align: left;
    }

    &__input-box {
      margin-bottom: 18px;
    }

    .btn {
      margin: 0;
    }

    .sb-modal-error {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

/* === MODAL AGE VERIFICATION  === */
.sb-modal--age-verification {
  .sb-modal__content {
    .sb-icon {
      width: 105px;
      height: 105px;
    }
  }
}

/* === MODAL ANONYMOUS BET  === */
.sb-modal--anonymous-bet {
  [class*='earlier-'] {
    margin-right: 5px;
  }

  [class*='current-'] {
    margin-left: 5px;
  }

  .sb-modal__content {
    overflow: scroll;
  }

  .sb-modal__text {
    margin-bottom: 25px;
  }

  .team {
    display: block;
    padding: 10px 0;
    color: var(--brand-color);
  }

  .earlier-selection + span {
    margin-left: 4px;
  }

  .earlier-rate,
  .current-rate {
    color: var(--white);
    font-weight: 700;
  }

  .current-rate {
    &.is-increased {
      color: #8aff00;
    }

    &.is-decreased {
      color: var(--c-error-1);
    }
  }
}

/* === MODAL MERCHANT PAYOUT  === */
.sb-modal--payout {
  .sb-modal__content {
    padding-bottom: 0;

    &::after,
    &::before {
      display: none;
    }
  }
}

/* === MODAL MERCHANT OPENED IN MY BETS === */
my-bets-details {
  .sb-modal__text {
    text-align: initial;
  }
}

/* === MODAL SHARED FULL  === */
.sb-modal--sharebet-big {
  .sb-modal__content {
    overflow: initial;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    margin-bottom: 0;
  }

  .sb-modal__text--big {
    margin: 80px 0 35px;
    text-align: center;
  }

  .sb-modal__share-link {
    font-size: 24px;
    text-align: center;
  }

  .sb-modal__bg {
    position: relative;
    flex-grow: 1;
    margin-top: 20px;

    &::after {
      position: absolute;
      bottom: -65px;
      left: 0;
      display: block;
      width: 100%;
      height: calc(100% + 65px);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('/img/new-version/backgrounds/modal-share-bet.png');
      background-position: 50% 100%;
      content: '';
    }
  }

  .btn--bg-light-grey {
    margin-top: 30px;
    background: transparent;
    font-size: 15px;
    font-weight: 800;
    font-style: italic;
    color: var(--brand-color);
  }
}

.sb-modal--cancel-withdrawal {
  .sb-modal__action {
    margin-bottom: 0;
  }
}

.sb-modal.bonus-regulations-modal {
  .sb-modal__content {
    overflow: scroll;
  }
}

.sb-modal--error {
  .sb-modal__text::first-letter {
    text-transform: uppercase;
  }
}

.sb-modal.additional-register-modal {
  z-index: 999;

  .sb-modal__content {
    overflow: scroll;
  }
}

.geo-blocking {
  position: fixed;
  top: 0;
  right: 0;
  bottom: -80px;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--body);
  background-image: url('/img/intro.png');
  background-size: cover;

  img {
    height: 100px;
    margin-bottom: 20px;
  }

  button {
    display: block;
    width: 200px;
    margin: 20px auto 0;
    padding: 15px 5px;
    background-color: var(--brand-color);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -0.7px;
  }
}

.modal-paymenticon {
  max-height: 100vh;
  overflow: hidden !important;
  -webkit-overflow-scrolling: auto;

  .col-d-12 {
    width: 100%;
    overflow: scroll;
    max-height: 94vh;
    height: 94vh;
    padding: 0 5px 1vh;
  }

  .paymenticon-iframe {
    display: block;
    height: 100%;
    margin: 0 auto;
    min-width: 100%;
    width: 100%;
  }
}

//temp overwrites old payments withdraw modal
.modal-select {
  li {
    margin-bottom: 12px;
  }

  .modal-list-wrapp {
    max-height: 100%;
    overflow-y: scroll;
  }
}

.sb-modal--information {
  .sb-modal__action {
    margin-bottom: 0;
  }
}

/* === MODAL REMIND PASSWORD === */
.sb-modal--remind-password-with-phone {
  .sb-modal__text {
    margin-bottom: 20px;
  }

  .user-form-label {
    position: static;
    display: block;
    margin-bottom: 10px;
    color: var(--brand-color);
    font-size: 14px;
    font-style: italic;
    font-weight: 800;
    line-height: 1;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  .user-form-input {
    height: 45px;
    padding: 0 10px;
    background-color: #100e11;
    text-align: left;
    border: 2px solid #444;
    border-radius: var(--radius-primary);
  }

  .user-from-select-hint {
    display: flex;
    height: 45px;
    color: var(--f-grey-17);
    font-size: 13px;
    letter-spacing: -0.025em;
    border: 2px solid #444;
    border-radius: var(--radius-primary);
  }

  .user-form__row {
    position: relative;
    margin-bottom: 20px;

    &.has-phone-prefix {
      display: grid;
      grid-template-columns: 80px 1fr;
      column-gap: 12px;
    }
  }

  .hint-dropdown {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    border-radius: var(--radius-primary);

    &__selected {
      overflow: hidden;
      padding-left: 27px;
      background-position: 0 50%;
      background-size: 17px;
      background-repeat: no-repeat;
      color: #919191;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: -0.025em;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__overlay {
      display: none;
      z-index: 13;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 55%);
    }

    &__content {
      display: none;
      z-index: 14;
      position: fixed;
      top: calc((100vh - 267px) / 2);
      left: 50%;
      transform: translateX(-50%);
      flex-flow: column;
      width: 100%;
      max-width: 600px;
      padding-left: calc(13px + var(--safe-area-left));
      padding-right: calc(13px + var(--safe-area-right));
      border-radius: 3px;

      &.is-active {
        display: flex;

        & + div {
          display: block;
        }
      }
    }

    &__input {
      width: 100%;
      margin-bottom: 0;
      padding: 15px;
      color: var(--black);
      font-size: 12px;
      font-weight: 700;
      background: var(--white) url(/img/new-version/icons/grey/search.svg) no-repeat right 15px center/17px;
      border: initial;
      border-radius: initial;
      border-top-left-radius: var(--radius-primary);
      border-top-right-radius: var(--radius-primary);
      box-shadow: 0 1px 3px 1px rgb(0 0 0 / 21%);
      text-align: left;
    }

    &-error {
      display: none;
      padding: 9px 8px;
      background-color: #a90101;
      color: var(--white);
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
      border-bottom-left-radius: var(--radius-primary);
      border-bottom-right-radius: var(--radius-primary);

      &.is-active {
        display: block;
      }
    }

    &-list {
      overflow: auto;
      display: block;
      height: 100%;
      max-height: 220px;
      flex-grow: 1;
      background-color: #fafafa;
      color: var(--black);
      border-bottom-left-radius: var(--radius-primary);
      border-bottom-right-radius: var(--radius-primary);
      box-shadow: 0 1px 3px 1px rgb(0 0 0 / 21%);

      &__entry {
        overflow: hidden;
        padding: 9px 10px 9px 35px;
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: left 8px center;
        background-color: #fafafa;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: -0.025em;
        text-transform: initial;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}

.sb-modal--blocked {
  .sb-modal__content {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .sb-modal__text {
    margin-bottom: 25px;
  }

  .sb-modal__action {
    position: sticky;
    bottom: 0;
    background-color: #110f11;
    box-shadow: 0 0 15px 20px #110f11;
  }

  .sb-icon-prohibition,
  .sb-icon-age {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 40px;
  }

  .sb-modal__text--sm {
    color: #6b6b70;
  }

  .sb-modal__action {
    margin-bottom: 0;
  }
}

body[class*='iPhone'] {
  &.new-modal-login {
    min-height: 100vh !important;
  }

  .sb-modal {
    padding-top: calc(10px + env(safe-area-inset-top));
    padding-bottom: calc(10px + env(safe-area-inset-bottom));

    &--login {
      bottom: initial;
    }
  }

  .sb-modal--filter,
  betslip-compact .sb-modal--half-size {
    padding-bottom: 0;

    .sb-modal__content {
      padding-bottom: calc(10px + env(safe-area-inset-bottom));
    }
  }
}

.sb-modal.modal--reset-password .sb-modal__content {
  justify-content: end;

  .icon-logo {
    width: 180px;
    height: 45px;
    margin-bottom: 40px;
  }
}

.live-agent {
  .ds-modal-coupon.fullscreen { 
    .ds-modal {
      padding-bottom: var(--space-3);
    }
  }
}