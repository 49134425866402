.boa-widget {
  max-width: 768px;
  margin: 0 auto;
  overflow-x: none;
  max-height: 100%;
  height: 100%;
  letter-spacing: -0.05em;

  --boa-radius: var(--radius-second);
  --boa-distance-bottom: 25px;

  --article-text-color: var(--grey-2);
  --article-border: 3px solid var(--primary-color);

  --btn-color: var(--body-bg);
  --btn-color-active: var(--basic-color);
  --btn-border: 2px solid var(--basic-color);

  .btn {
    font-size: 14px;
    font-weight: 700;

    &--lv-jackpot,
    &--lv-jackpot-special {
      min-width: 200px;
      height: 51px;
      border: var(--btn-border);
      border-radius: var(--boa-radius);
      transition: color 0.3s ease, background-color 0.3s ease;
    }

    &--lv-jackpot-special,
    &--lv-jackpot:hover {
      color: var(--btn-color-active);
      background-color: var(--btn-color);
    }

    &--lv-jackpot-special:hover,
    &--lv-jackpot,
    &--active {
      color: var(--btn-color);
      background-color: var(--btn-color-active);
    }
  }

  .subpages {
    &-title {
      display: block;
      padding-bottom: 35px;
      color: var(--white);
      font-weight: 800;
      font-size: 26px;
    }
  }

  .article {
    &-container__title {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      .subpages-title {
        padding: 0;
      }
      .subpages-title__lv-jackpot {
        font-size: 26px;
        font-weight: 800;
      }
    }
    &-text {
      color: var(--article-text-color);
      padding-bottom: 49px;
      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      &-lv-jackpot {
        padding-bottom: var(--boa-distance-bottom);
      }
    }
    &-text-distinct {
      display: none;
    }
    &-text__container {
      &-lv-jackpot {
        margin-bottom: var(--boa-distance-bottom);
        border: var(--article-border);
        border-radius: var(--boa-radius);
      }
    }

    &-text__container {
      &-lv-jackpot {
        padding: var(--boa-distance-bottom);
      }
    }
  }
}
