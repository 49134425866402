@import 'sportsbook/mobile-web/shared/layout/header';

:root .header-container {
  --header-bg: var(--body);
  --header-border-bottom: 2px solid var(--bg-1);
  --hamburger-bg: #fff;
  --logo-title-color: #fff;
  --cash-border: 2px solid var(--primary-green);

  .btn-register {
    --bg: var(--basic-color);
    --color: #000;
  }
}

.header-bar {
  --header-bar-bg: var(--bg-color, #342b14);
  --icon-bg: var(--black);
  --icon-radius: 8px;
  --header-bar-radius: 0;
  --btn-radius: 12px;
  --btn-bg: var(--basic-color);
  --close-icon-fill: var(--basic-color);
  --title-color: var(--white);
  --subtitle-color: var(--basic-color);
  --btn-border-color: var(--basic-color);
  --btn-color: var(--black);
  --logo-color: var(--white);
}

.header-nav {
  .icon-search {
    width: 19px;
    height: 19px;
  }

  .icon-user {
    width: 16px;
    height: 18px;
  }

  .icon-user-vip {
    width: 16px;
    height: 7px;
  }
}

.deposit-bar {
  --deposti-bar-bg: rgba(251, 205, 0, 0.15);
}
