@import 'sportsbook/mobile-web/shared/pages/virtual-sports';

.sb-vr-sports,
bcf-virtual-sports-game-page,
bcf-virtual-game-cards-game-page {
  display: block;
  min-height: calc(100vh - (95px + var(--top-space-height) + var(--safe-area-top)) - (66px + var(--safe-area-bottom)));
}

.sb-virtual-iframe {
  z-index: 15;
  position: fixed;
  top: calc(0px + var(--safe-area-top));
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  .virtual-back-container {
    display: flex;
    align-items: center;
    padding: var(--space-3);
    background-color: var(--body-bg);
  }
  .virtual-back__link {
    padding-left: var(--space-3);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }
  .icon-arrow-left {
    width: 16px;
    height: 16px;
    path {
      fill: var(--white);
    }
  }
}

.virtual-football {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/virtual-football.jpg');
}

.virtual-horse-racing {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/virtual-racing.jpg');
}

.virtual-racing {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/virtual-racing.jpg');
}

.virtual-tennis {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/virtual-tennis.jpg');
}

.instant-racing {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/instant-racing.jpg');
}

.instant-football {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/instant-football.jpg');
}

.golden-race-virtual-football {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/golden-virtual-football.jpg');
}

.golden-race-virtual-motorbikes-racing::before,
.golden-race-virtual-racing {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/golden-virtual-racing.jpg');
}

.golden-race-virtual-horse-racing {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/golden-virtual-horse-racing.jpg');
}

.golden-race-virtual-speedway {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/golden-virtual-speedway.jpg');
}

.golden-race-virtual-greyhound-racing {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/golden-virtual-greyhound-racing.jpg');
}

.golden-race-virtual-mma {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/golden-virtual-mma.jpg');
}

.golden-race-virtual-motorbikes-racing {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/motorbike-racing.jpg');
}

.war,
.war-plus {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/game-cards-war.jpg');
}

.poker,
.polish-poker,
.indian-poker {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/game-cards-poker.jpg');
}

.blackjack {
  background-image: url('#{$assets-path}/images/bcf/mobile/img/new-version/backgrounds/game-cards-blackjack.jpg');
}

virtual-game-cards .sb-virtual-iframe iframe {
  right: initial;
  bottom: initial;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
