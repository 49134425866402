/****************************************************************************
1. ABSTRACT
****************************************************************************/

@import 'sportsbook/mobile-web/lvbet/lvbetpl/abstract/reset';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/abstract/env';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/abstract/animations';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/abstract/mixins';

/****************************************************************************
1. ENVIROMENTS
****************************************************************************/
@import 'env-variables';

/****************************************************************************
2. BASIC
****************************************************************************/
@import 'sportsbook/mobile-web/lvbet/lvbetpl/basic/typography';

/****************************************************************************
3. SHARED
****************************************************************************/
// @import "shared-elements/tab-bar";
// @import "shared-icons/countries/png-path/countries";
@import 'shared/common/lvbet/self-exclusion-reminder/self-exclusion-reminder';

/****************************************************************************
4. IMAGES
****************************************************************************/
@import 'sportsbook/mobile-web/lvbet/lvbetpl/images/icons';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/images/icons-inline';

/****************************************************************************
4.  COMPONENTS
****************************************************************************/
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/buttons';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/basic';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/betslip';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/open-game';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/fast-bet';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/odds-table';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/upcomming';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/datepicker';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/sportsbook';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/forms/forms';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/components/keyboard';

/****************************************************************************
4.PAGES
****************************************************************************/

@import 'sportsbook/mobile-web/lvbet/lvbetpl/pages/profile';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/pages/modals';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/pages/esport';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/pages/virtual-sports';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/pages/search';

/****************************************************************************
5. LAYOUT
****************************************************************************/
@import 'sportsbook/mobile-web/lvbet/lvbetpl/layout/general';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/layout/header';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/layout/breadcrumbs';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/layout/bottom-nav';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/layout/side-menu';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/layout/right-menu';
@import 'sportsbook/mobile-web/lvbet/lvbetpl/layout/footer';

/****************************************************************************
6. WIDGETS
****************************************************************************/
@import 'sportsbook/mobile-web/lvbet/lvbetpl/widgets/widgets';

/****************************************************************************
7. THEME
****************************************************************************/
@import 'sportsbook/mobile-web/lvbet/lvbetpl/theme/variables';
