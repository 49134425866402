@import 'sportsbook/mobile-web/shared/components/keyboard';

.betslip-keyboard {
  --keyboard-bg: #000;
  --keyboard-grey-color: #636363;

  .btn {
    --btn-bg: #7e7e7e;
    --btn-color: #fff;
    --btn-border: 2px solid #313137;
    --icon-ctrl-fill: #7e7e7e;
  }
}

.betslip-keyboard__close {
  --btn-close-border: 2px solid #fbcb00;
}

.betslip-keyboard__input {
  --input-bg: #fff;
  --input-color: #000;
}

.betslip-keyboard-error {
  --error-color: #ad0000;
}